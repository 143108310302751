import {
  CookiesSettings,
  CookiesSettingsProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';
import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useCookiesSettingsQuery } from '@/api';
import { cookieExpirationDate } from '@/constants/cookies';

export const CookieSetting = () => {
  const router = useRouter();
  const { locale } = router;

  const { data } = useCookiesSettingsQuery({
    variables: { locale: locale || 'en' },
  });

  const [visible, setVisible] = useState(false);
  const [optionalCookies, setOptional] = useState(
    hasCookie('renergy_cookie_consent_optional') &&
      getCookie('renergy_cookie_consent_optional') === 'true',
  );

  const settingsData = data?.cookiesSetting?.data?.attributes;

  const onSave = () => {
    setCookie('renergy_cookie_consent_optional', optionalCookies, {
      expires: cookieExpirationDate,
    });
    setVisible(false);
  };

  useEffect(() => {
    if (window.location.hash && !visible) {
      const { pathname, search } = window.location;
      const newPath = pathname + search;
      router.replace(newPath, undefined, { shallow: true });
    }
  }, [router, visible]);

  useEffect(() => {
    const handleUrlChange = () => {
      if (window.location.hash.substring(1).includes('cookiesSettings')) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('hashchange', handleUrlChange);

    return () => {
      window.removeEventListener('hashchange', handleUrlChange);
    };
  }, []);

  const modalData: CookiesSettingsProps = {
    heading: settingsData?.title || '',
    closeModal: () => setVisible(false),
    useCase: 'energy' as useCaseType,
    actionButton: {
      label: settingsData?.actionButtonLabel || '',
      onClick: () => onSave(),
      useCase: 'energy' as useCaseType,
    },
    moreInfoLink: {
      label: settingsData?.redirectButton?.label || '',
      path: settingsData?.redirectButton?.path || '',
      useCase: 'energy' as useCaseType,
    },
    isModalOpen: visible,
    tableContentItems: [
      {
        heading: settingsData?.necessaryCookiesHeading || '',
        toggleSwitch: {
          initialValue: true,
          isFixed: true,
          onChange: () => {},
          useCase: 'energy' as useCaseType,
        },
        tableContent: {
          parentIndex: 1,
          useCase: 'energy',
          text: settingsData?.necessaryCookiesTable?.richText || '',
          tableData: {
            headings: (
              settingsData?.necessaryCookiesTable?.tableHead || []
            ).map((item) => item?.headingItem || ''),
            values: (
              settingsData?.necessaryCookiesTable?.tableRow?.table_rows?.data ||
              []
            ).map((row) =>
              (row.attributes?.rows || []).map((item) => item?.item || ''),
            ),
          },
        },
      },
      {
        heading: settingsData?.optionalCookiesHeading || '',
        toggleSwitch: {
          initialValue: optionalCookies,
          isFixed: false,
          onChange: () => setOptional(!optionalCookies),
          useCase: 'energy' as useCaseType,
        },
        tableContent: {
          parentIndex: 2,
          useCase: 'energy',
          text: settingsData?.optionalCookiesTable?.richText || '',
          tableData: {
            headings: (settingsData?.optionalCookiesTable?.tableHead || []).map(
              (item) => item?.headingItem || '',
            ),
            values: (
              settingsData?.optionalCookiesTable?.tableRow?.table_rows?.data ||
              []
            ).map((row) =>
              (row.attributes?.rows || []).map((item) => item?.item || ''),
            ),
          },
        },
      },
    ],
  };

  if (visible && data) return <CookiesSettings {...modalData} />;
};
