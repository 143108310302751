import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CookiePolicyCookiePolicyDynamicZoneInput: { input: any; output: any };
  DateTime: { input: any; output: any };
  I18NLocaleCode: { input: any; output: any };
  JSON: { input: any; output: any };
  LegalNoticeLegalNoticeDynamicZoneInput: { input: any; output: any };
  PrivacyPolicyPoliciesDynamicZoneInput: { input: any; output: any };
  Upload: { input: any; output: any };
  WhistleblowingPolicyPoliciesDynamicZoneInput: { input: any; output: any };
};

export type About = {
  __typename?: 'About';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  facilitySection?: Maybe<ComponentUiImageFeatureSection>;
  heroImageSection?: Maybe<ComponentUiHeroImageSection>;
  imageFeatureSection?: Maybe<ComponentUiImageFeatureSection>;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<AboutRelationResponseCollection>;
  newsroom?: Maybe<ComponentSharedNewsroom>;
  numbersSection?: Maybe<ComponentSharedNumbersSection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  singleTeamMemberSection?: Maybe<ComponentUiSingleTeamMemberSection>;
  slug?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  slug_fr?: Maybe<Scalars['String']['output']>;
  slug_hr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AboutLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type AboutEntity = {
  __typename?: 'AboutEntity';
  attributes?: Maybe<About>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type AboutEntityResponse = {
  __typename?: 'AboutEntityResponse';
  data?: Maybe<AboutEntity>;
};

export type AboutInput = {
  facilitySection?: InputMaybe<ComponentUiImageFeatureSectionInput>;
  heroImageSection?: InputMaybe<ComponentUiHeroImageSectionInput>;
  imageFeatureSection?: InputMaybe<ComponentUiImageFeatureSectionInput>;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  newsroom?: InputMaybe<ComponentSharedNewsroomInput>;
  numbersSection?: InputMaybe<ComponentSharedNumbersSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  singleTeamMemberSection?: InputMaybe<ComponentUiSingleTeamMemberSectionInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
  slug_fr?: InputMaybe<Scalars['String']['input']>;
  slug_hr?: InputMaybe<Scalars['String']['input']>;
};

export type AboutRelationResponseCollection = {
  __typename?: 'AboutRelationResponseCollection';
  data: Array<AboutEntity>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ComponentAboutLogoCardsSection = {
  __typename?: 'ComponentAboutLogoCardsSection';
  cards?: Maybe<Array<Maybe<ComponentUiLogoCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentAboutLogoCardsSectionCardsArgs = {
  filters?: InputMaybe<ComponentUiLogoCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentAboutMap = {
  __typename?: 'ComponentAboutMap';
  id: Scalars['ID']['output'];
  leftMapImage?: Maybe<UploadFileEntityResponse>;
  leftMapImageMobile?: Maybe<UploadFileEntityResponse>;
  leftMapTitle?: Maybe<Scalars['String']['output']>;
  rightMapImage?: Maybe<UploadFileEntityResponse>;
  rightMapImageMobile?: Maybe<UploadFileEntityResponse>;
  rightMapTitle?: Maybe<Scalars['String']['output']>;
};

export type ComponentAboutTeam = {
  __typename?: 'ComponentAboutTeam';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  teammates?: Maybe<Array<Maybe<ComponentAboutTeamMate>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentAboutTeamTeammatesArgs = {
  filters?: InputMaybe<ComponentAboutTeamMateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentAboutTeamMate = {
  __typename?: 'ComponentAboutTeamMate';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  logos?: Maybe<UploadFileRelationResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  secondPosition?: Maybe<Scalars['String']['output']>;
};

export type ComponentAboutTeamMateLogosArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentAboutTeamMateFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentAboutTeamMateFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentAboutTeamMateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentAboutTeamMateFiltersInput>>>;
  position?: InputMaybe<StringFilterInput>;
  secondPosition?: InputMaybe<StringFilterInput>;
};

export type ComponentFooterColumn = {
  __typename?: 'ComponentFooterColumn';
  id: Scalars['ID']['output'];
  lable?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentUiButton>>>;
};

export type ComponentFooterColumnLinkArgs = {
  filters?: InputMaybe<ComponentUiButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterColumnFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
  lable?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentUiButtonFiltersInput>;
  not?: InputMaybe<ComponentFooterColumnFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFooterColumnFiltersInput>>>;
};

export type ComponentFooterColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lable?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentUiButtonInput>>>;
};

export type ComponentFooterContact = {
  __typename?: 'ComponentFooterContact';
  address?: Maybe<ComponentNavigationLink>;
  email?: Maybe<ComponentNavigationLink>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterContactInput = {
  address?: InputMaybe<ComponentNavigationLinkInput>;
  email?: InputMaybe<ComponentNavigationLinkInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFooterPolicies = {
  __typename?: 'ComponentFooterPolicies';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentUiButton>>>;
};

export type ComponentFooterPoliciesLinkArgs = {
  filters?: InputMaybe<ComponentUiButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFooterPoliciesInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentUiButtonInput>>>;
};

export type ComponentFooterSocials = {
  __typename?: 'ComponentFooterSocials';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  linkenin?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentFooterSocialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  linkenin?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentGetInTouchCheckBox = {
  __typename?: 'ComponentGetInTouchCheckBox';
  areaLabel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  labelBefore?: Maybe<Scalars['String']['output']>;
  labelLink?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentGetInTouchCheckBoxInput = {
  areaLabel?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  labelBefore?: InputMaybe<Scalars['String']['input']>;
  labelLink?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentGetInTouchModal = {
  __typename?: 'ComponentGetInTouchModal';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ComponentGetInTouchModalInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentGetInTouchOption = {
  __typename?: 'ComponentGetInTouchOption';
  id: Scalars['ID']['output'];
  isFullWidth: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  type: Enum_Componentgetintouchoption_Type;
  value: Scalars['String']['output'];
};

export type ComponentGetInTouchOptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentGetInTouchOptionFiltersInput>>>;
  isFullWidth?: InputMaybe<BooleanFilterInput>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentGetInTouchOptionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentGetInTouchOptionFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentGetInTouchOptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isFullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Componentgetintouchoption_Type>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationContactInfo = {
  __typename?: 'ComponentNavigationContactInfo';
  address?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationContactInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationHomeLink = {
  __typename?: 'ComponentNavigationHomeLink';
  areaLabel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationHomeLinkInput = {
  areaLabel?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationLink = {
  __typename?: 'ComponentNavigationLink';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationLinkInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentNavigationOpenMenuColumn = {
  __typename?: 'ComponentNavigationOpenMenuColumn';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Array<Maybe<ComponentNavigationLink>>>;
  socials?: Maybe<ComponentNavigationSocials>;
};

export type ComponentNavigationOpenMenuColumnLinkArgs = {
  filters?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentNavigationOpenMenuColumnFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>>
  >;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  not?: InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>>
  >;
  socials?: InputMaybe<ComponentNavigationSocialsFiltersInput>;
};

export type ComponentNavigationOpenMenuColumnInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkInput>>>;
  socials?: InputMaybe<ComponentNavigationSocialsInput>;
};

export type ComponentNavigationSocials = {
  __typename?: 'ComponentNavigationSocials';
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  linkedIn?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ComponentNavigationSocialsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentNavigationSocialsFiltersInput>>>;
  instagram?: InputMaybe<StringFilterInput>;
  linkedIn?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentNavigationSocialsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentNavigationSocialsFiltersInput>>>;
  tiktok?: InputMaybe<StringFilterInput>;
  youtube?: InputMaybe<StringFilterInput>;
};

export type ComponentNavigationSocialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesDivider = {
  __typename?: 'ComponentPoliciesDivider';
  Divider: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesDownloadLink = {
  __typename?: 'ComponentPoliciesDownloadLink';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesMainHeading = {
  __typename?: 'ComponentPoliciesMainHeading';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentPoliciesRowItem = {
  __typename?: 'ComponentPoliciesRowItem';
  id: Scalars['ID']['output'];
  item?: Maybe<Scalars['String']['output']>;
};

export type ComponentPoliciesRowItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
  item?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemFiltersInput>>>;
};

export type ComponentPoliciesRowItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentPoliciesTableHead = {
  __typename?: 'ComponentPoliciesTableHead';
  headingItem?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentPoliciesTableHeadFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadFiltersInput>>>;
  headingItem?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPoliciesTableHeadFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadFiltersInput>>>;
};

export type ComponentPoliciesTableHeadInput = {
  headingItem?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentPoliciesTableRow = {
  __typename?: 'ComponentPoliciesTableRow';
  id: Scalars['ID']['output'];
  table_rows?: Maybe<TableRowRelationResponseCollection>;
};

export type ComponentPoliciesTableRowTable_RowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableRowInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  table_rows?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ComponentPoliciesTableTextContent = {
  __typename?: 'ComponentPoliciesTableTextContent';
  id: Scalars['ID']['output'];
  richText?: Maybe<Scalars['JSON']['output']>;
  tableHead?: Maybe<Array<Maybe<ComponentPoliciesTableHead>>>;
  tableRow?: Maybe<ComponentPoliciesTableRow>;
};

export type ComponentPoliciesTableTextContentTableHeadArgs = {
  filters?: InputMaybe<ComponentPoliciesTableHeadFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentPoliciesTableTextContentInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  richText?: InputMaybe<Scalars['JSON']['input']>;
  tableHead?: InputMaybe<Array<InputMaybe<ComponentPoliciesTableHeadInput>>>;
  tableRow?: InputMaybe<ComponentPoliciesTableRowInput>;
};

export type ComponentPoliciesTitleTextContent = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedIconCardsSection = {
  __typename?: 'ComponentSharedIconCardsSection';
  anchor?: Maybe<Scalars['String']['output']>;
  iconCard?: Maybe<Array<Maybe<ComponentUiIconCard>>>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedIconCardsSectionIconCardArgs = {
  filters?: InputMaybe<ComponentUiIconCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedIconCardsSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  iconCard?: InputMaybe<Array<InputMaybe<ComponentUiIconCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedIconSpec = {
  __typename?: 'ComponentSharedIconSpec';
  icon?: Maybe<ComponentUiImage>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedIconSpecFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedIconSpecFiltersInput>>>;
  icon?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentSharedIconSpecFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedIconSpecFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedIconSpecInput = {
  icon?: InputMaybe<ComponentUiImageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedLargeImageSection = {
  __typename?: 'ComponentSharedLargeImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedLargeImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedMetaSocial = {
  __typename?: 'ComponentSharedMetaSocial';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars['String']['output'];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedNewsroom = {
  __typename?: 'ComponentSharedNewsroom';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<ComponentUiButton>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedNewsroomInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<ComponentUiButtonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedNumbersSection = {
  __typename?: 'ComponentSharedNumbersSection';
  cards?: Maybe<Array<Maybe<ComponentUiNumbersCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedNumbersSectionCardsArgs = {
  filters?: InputMaybe<ComponentUiNumbersCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedNumbersSectionInput = {
  cards?: InputMaybe<Array<InputMaybe<ComponentUiNumbersCardInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  metaDescription: Scalars['String']['output'];
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars['String']['output'];
  metaViewport?: Maybe<Scalars['String']['output']>;
  structuredData?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaRobots?: InputMaybe<Scalars['String']['input']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  metaViewport?: InputMaybe<Scalars['String']['input']>;
  structuredData?: InputMaybe<Scalars['JSON']['input']>;
};

export type ComponentSharedTextBlock = {
  __typename?: 'ComponentSharedTextBlock';
  heading?: Maybe<ComponentUiHeading>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  sections?: Maybe<Array<Maybe<ComponentUiTextBlockSection>>>;
};

export type ComponentSharedTextBlockSectionsArgs = {
  filters?: InputMaybe<ComponentUiTextBlockSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTextImageSection = {
  __typename?: 'ComponentSharedTextImageSection';
  anchor?: Maybe<Scalars['String']['output']>;
  iconSpec?: Maybe<Array<Maybe<ComponentSharedIconSpec>>>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  text?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTextImageSectionIconSpecArgs = {
  filters?: InputMaybe<ComponentSharedIconSpecFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTextImageSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  iconSpec?: InputMaybe<Array<InputMaybe<ComponentSharedIconSpecInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  text?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedToggleText = {
  __typename?: 'ComponentSharedToggleText';
  firstText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  secondText: Scalars['String']['output'];
};

export type ComponentSharedToggleTextInput = {
  firstText?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  secondText?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedTwoImagesGrid = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imagesGridItems?: Maybe<Array<Maybe<ComponentUiImagesGridItem>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedTwoImagesGridImagesGridItemsArgs = {
  filters?: InputMaybe<ComponentUiImagesGridItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTwoVideosGrid = {
  __typename?: 'ComponentSharedTwoVideosGrid';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  videosGridItems?: Maybe<Array<Maybe<ComponentUiVideosGridItem>>>;
};

export type ComponentSharedTwoVideosGridVideosGridItemsArgs = {
  filters?: InputMaybe<ComponentUiVideosGridItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedTwoVideosGridInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  videosGridItems?: InputMaybe<
    Array<InputMaybe<ComponentUiVideosGridItemInput>>
  >;
};

export type ComponentSharedValuesSection = {
  __typename?: 'ComponentSharedValuesSection';
  anchor?: Maybe<Scalars['String']['output']>;
  cards?: Maybe<Array<Maybe<ComponentUiIconCard>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedValuesSectionCardsArgs = {
  filters?: InputMaybe<ComponentUiIconCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiArticle = {
  __typename?: 'ComponentUiArticle';
  alt?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  link_path?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentuiarticle_Tagcolor>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiButton = {
  __typename?: 'ComponentUiButton';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiButtonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiButtonFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiButtonFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentUiButtonInput = {
  file?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiCommitment = {
  __typename?: 'ComponentUiCommitment';
  alt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentuicommitment_Tagcolor>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiFeature = {
  __typename?: 'ComponentUiFeature';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHeading = {
  __typename?: 'ComponentUiHeading';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHero = {
  __typename?: 'ComponentUiHero';
  button?: Maybe<ComponentUiButton>;
  fullVideo?: Maybe<ComponentUiVideo>;
  fullVideoLength?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiHeroImageSection = {
  __typename?: 'ComponentUiHeroImageSection';
  button?: Maybe<ComponentUiButton>;
  heroImage?: Maybe<ComponentUiImage>;
  id: Scalars['ID']['output'];
  previewVideo?: Maybe<ComponentUiVideo>;
  previewVideoLength?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiHeroImageSectionInput = {
  button?: InputMaybe<ComponentUiButtonInput>;
  heroImage?: InputMaybe<ComponentUiImageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  previewVideo?: InputMaybe<ComponentUiVideoInput>;
  previewVideoLength?: InputMaybe<Scalars['String']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiHeroVideoSection = {
  __typename?: 'ComponentUiHeroVideoSection';
  button?: Maybe<ComponentUiButton>;
  heroVideo?: Maybe<ComponentUiVideo>;
  id: Scalars['ID']['output'];
  previewVideo?: Maybe<ComponentUiVideo>;
  previewVideoLength?: Maybe<Scalars['String']['output']>;
  showPreviewVideo?: Maybe<Scalars['Boolean']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiIconCard = {
  __typename?: 'ComponentUiIconCard';
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiIconCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiIconCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiIconCardFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiIconCardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiIconSpec = {
  __typename?: 'ComponentUiIconSpec';
  icon?: Maybe<ComponentUiImage>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiImage = {
  __typename?: 'ComponentUiImage';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiImageFeatureSection = {
  __typename?: 'ComponentUiImageFeatureSection';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Array<Maybe<ComponentUiImage>>>;
  richText?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiImageFeatureSectionImageArgs = {
  filters?: InputMaybe<ComponentUiImageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiImageFeatureSectionFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentUiImageFeatureSectionFiltersInput>>
  >;
  image?: InputMaybe<ComponentUiImageFiltersInput>;
  not?: InputMaybe<ComponentUiImageFeatureSectionFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentUiImageFeatureSectionFiltersInput>>
  >;
  richText?: InputMaybe<JsonFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiImageFeatureSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Array<InputMaybe<ComponentUiImageInput>>>;
  richText?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiImageFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
  not?: InputMaybe<ComponentUiImageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImageFiltersInput>>>;
};

export type ComponentUiImageInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  img_desktop?: InputMaybe<Scalars['ID']['input']>;
  img_mobile?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiImagesGridItem = {
  __typename?: 'ComponentUiImagesGridItem';
  alt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img_desktop?: Maybe<UploadFileEntityResponse>;
  img_mobile?: Maybe<UploadFileEntityResponse>;
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentuiimagesgriditem_Tagcolor>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiImagesGridItemFiltersInput = {
  alt?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentUiImagesGridItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiImagesGridItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiImagesGridItemFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiLogo = {
  __typename?: 'ComponentUiLogo';
  alt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  svg?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiLogoCard = {
  __typename?: 'ComponentUiLogoCard';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<UploadFileEntityResponse>;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiLogoCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiLogoCardFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiLogoCardFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiLogoCardFiltersInput>>>;
  path?: InputMaybe<StringFilterInput>;
};

export type ComponentUiNumbersCard = {
  __typename?: 'ComponentUiNumbersCard';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiNumbersCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiNumbersCardFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiNumbersCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiNumbersCardFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentUiNumbersCardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiSingleTeamMemberSection = {
  __typename?: 'ComponentUiSingleTeamMemberSection';
  anchor?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ComponentUiImage>;
  positionText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentUiSingleTeamMemberSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ComponentUiImageInput>;
  positionText?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUiTextBlockSection = {
  __typename?: 'ComponentUiTextBlockSection';
  icon?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['JSON']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentUiTextBlockSectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiTextBlockSectionFiltersInput>>>;
  not?: InputMaybe<ComponentUiTextBlockSectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiTextBlockSectionFiltersInput>>>;
  text?: InputMaybe<JsonFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentUiVideo = {
  __typename?: 'ComponentUiVideo';
  autoplay?: Maybe<Scalars['Boolean']['output']>;
  controls?: Maybe<Scalars['Boolean']['output']>;
  fullscreen?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  loop?: Maybe<Scalars['Boolean']['output']>;
  mobilePoster?: Maybe<UploadFileEntityResponse>;
  muted?: Maybe<Scalars['Boolean']['output']>;
  pauseVideo?: Maybe<Scalars['Boolean']['output']>;
  poster?: Maybe<UploadFileEntityResponse>;
  poster_alt?: Maybe<Scalars['String']['output']>;
  video?: Maybe<UploadFileEntityResponse>;
};

export type ComponentUiVideoFeatureSection = {
  __typename?: 'ComponentUiVideoFeatureSection';
  anchor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  richText?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Array<Maybe<ComponentUiVideo>>>;
};

export type ComponentUiVideoFeatureSectionVideoArgs = {
  filters?: InputMaybe<ComponentUiVideoFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentUiVideoFeatureSectionFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<
    Array<InputMaybe<ComponentUiVideoFeatureSectionFiltersInput>>
  >;
  not?: InputMaybe<ComponentUiVideoFeatureSectionFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentUiVideoFeatureSectionFiltersInput>>
  >;
  richText?: InputMaybe<JsonFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  video?: InputMaybe<ComponentUiVideoFiltersInput>;
};

export type ComponentUiVideoFeatureSectionInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  richText?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Array<InputMaybe<ComponentUiVideoInput>>>;
};

export type ComponentUiVideoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiVideoFiltersInput>>>;
  autoplay?: InputMaybe<BooleanFilterInput>;
  controls?: InputMaybe<BooleanFilterInput>;
  fullscreen?: InputMaybe<BooleanFilterInput>;
  loop?: InputMaybe<BooleanFilterInput>;
  muted?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentUiVideoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiVideoFiltersInput>>>;
  pauseVideo?: InputMaybe<BooleanFilterInput>;
  poster_alt?: InputMaybe<StringFilterInput>;
};

export type ComponentUiVideoInput = {
  autoplay?: InputMaybe<Scalars['Boolean']['input']>;
  controls?: InputMaybe<Scalars['Boolean']['input']>;
  fullscreen?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  loop?: InputMaybe<Scalars['Boolean']['input']>;
  mobilePoster?: InputMaybe<Scalars['ID']['input']>;
  muted?: InputMaybe<Scalars['Boolean']['input']>;
  pauseVideo?: InputMaybe<Scalars['Boolean']['input']>;
  poster?: InputMaybe<Scalars['ID']['input']>;
  poster_alt?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentUiVideosGridItem = {
  __typename?: 'ComponentUiVideosGridItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  tag?: Maybe<Scalars['String']['output']>;
  tagColor?: Maybe<Enum_Componentuivideosgriditem_Tagcolor>;
  title?: Maybe<Scalars['String']['output']>;
  video?: Maybe<ComponentUiVideo>;
};

export type ComponentUiVideosGridItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentUiVideosGridItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentUiVideosGridItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentUiVideosGridItemFiltersInput>>>;
  tag?: InputMaybe<StringFilterInput>;
  tagColor?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  video?: InputMaybe<ComponentUiVideoFiltersInput>;
};

export type ComponentUiVideosGridItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  tagColor?: InputMaybe<Enum_Componentuivideosgriditem_Tagcolor>;
  title?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<ComponentUiVideoInput>;
};

export type ContactFomResponse = {
  __typename?: 'ContactFomResponse';
  error?: Maybe<ErrorResult>;
  id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactFormDataInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  terms: Scalars['Boolean']['input'];
};

export type CookieBanner = {
  __typename?: 'CookieBanner';
  acceptButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  declineButtonLabel?: Maybe<Scalars['String']['output']>;
  editButtonLabel?: Maybe<Scalars['String']['output']>;
  extraLinks?: Maybe<Array<Maybe<ComponentUiButton>>>;
  heading?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<CookieBannerRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['JSON']['output']>;
  toggleText?: Maybe<ComponentSharedToggleText>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookieBannerExtraLinksArgs = {
  filters?: InputMaybe<ComponentUiButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CookieBannerLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type CookieBannerEntity = {
  __typename?: 'CookieBannerEntity';
  attributes?: Maybe<CookieBanner>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookieBannerEntityResponse = {
  __typename?: 'CookieBannerEntityResponse';
  data?: Maybe<CookieBannerEntity>;
};

export type CookieBannerInput = {
  acceptButtonLabel?: InputMaybe<Scalars['String']['input']>;
  declineButtonLabel?: InputMaybe<Scalars['String']['input']>;
  editButtonLabel?: InputMaybe<Scalars['String']['input']>;
  extraLinks?: InputMaybe<Array<InputMaybe<ComponentUiButtonInput>>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
  toggleText?: InputMaybe<ComponentSharedToggleTextInput>;
};

export type CookieBannerRelationResponseCollection = {
  __typename?: 'CookieBannerRelationResponseCollection';
  data: Array<CookieBannerEntity>;
};

export type CookiePolicy = {
  __typename?: 'CookiePolicy';
  cookiePolicy?: Maybe<Array<Maybe<CookiePolicyCookiePolicyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<CookiePolicyRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  slug_fr?: Maybe<Scalars['String']['output']>;
  slug_hr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiePolicyLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type CookiePolicyCookiePolicyDynamicZone =
  | ComponentPoliciesDivider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type CookiePolicyEntity = {
  __typename?: 'CookiePolicyEntity';
  attributes?: Maybe<CookiePolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiePolicyEntityResponse = {
  __typename?: 'CookiePolicyEntityResponse';
  data?: Maybe<CookiePolicyEntity>;
};

export type CookiePolicyInput = {
  cookiePolicy?: InputMaybe<
    Array<Scalars['CookiePolicyCookiePolicyDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
  slug_fr?: InputMaybe<Scalars['String']['input']>;
  slug_hr?: InputMaybe<Scalars['String']['input']>;
};

export type CookiePolicyRelationResponseCollection = {
  __typename?: 'CookiePolicyRelationResponseCollection';
  data: Array<CookiePolicyEntity>;
};

export type CookiesSetting = {
  __typename?: 'CookiesSetting';
  actionButtonLabel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<CookiesSettingRelationResponseCollection>;
  necessaryCookiesHeading?: Maybe<Scalars['String']['output']>;
  necessaryCookiesTable?: Maybe<ComponentPoliciesTableTextContent>;
  optionalCookiesHeading?: Maybe<Scalars['String']['output']>;
  optionalCookiesTable?: Maybe<ComponentPoliciesTableTextContent>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  redirectButton?: Maybe<ComponentUiButton>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CookiesSettingLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type CookiesSettingEntity = {
  __typename?: 'CookiesSettingEntity';
  attributes?: Maybe<CookiesSetting>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CookiesSettingEntityResponse = {
  __typename?: 'CookiesSettingEntityResponse';
  data?: Maybe<CookiesSettingEntity>;
};

export type CookiesSettingInput = {
  actionButtonLabel?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  necessaryCookiesTable?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  optionalCookiesHeading?: InputMaybe<Scalars['String']['input']>;
  optionalCookiesTable?: InputMaybe<ComponentPoliciesTableTextContentInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  redirectButton?: InputMaybe<ComponentUiButtonInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CookiesSettingRelationResponseCollection = {
  __typename?: 'CookiesSettingRelationResponseCollection';
  data: Array<CookiesSettingEntity>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Componentgetintouchoption_Type {
  Message = 'message',
  Text = 'text',
}

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export enum Enum_Componentuiarticle_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Componentuicommitment_Tagcolor {
  Black = 'black',
  Gray = 'gray',
}

export enum Enum_Componentuiimagesgriditem_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Componentuivideosgriditem_Tagcolor {
  Dark = 'dark',
  Default = 'default',
  Light = 'light',
}

export enum Enum_Sitemapsitemap_Type {
  DefaultHreflang = 'default_hreflang',
  Index = 'index',
}

export enum Enum_Translatebatchtranslatejob_Status {
  Cancelled = 'cancelled',
  Created = 'created',
  Failed = 'failed',
  Finished = 'finished',
  Paused = 'paused',
  Running = 'running',
  Setup = 'setup',
}

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type ErrorResult = {
  __typename?: 'ErrorResult';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  message?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type Footer = {
  __typename?: 'Footer';
  column?: Maybe<Array<Maybe<ComponentFooterColumn>>>;
  contact?: Maybe<ComponentFooterContact>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<FooterRelationResponseCollection>;
  policies?: Maybe<ComponentFooterPolicies>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  socials?: Maybe<ComponentFooterSocials>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FooterColumnArgs = {
  filters?: InputMaybe<ComponentFooterColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FooterLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type FooterEntity = {
  __typename?: 'FooterEntity';
  attributes?: Maybe<Footer>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FooterEntityResponse = {
  __typename?: 'FooterEntityResponse';
  data?: Maybe<FooterEntity>;
};

export type FooterInput = {
  column?: InputMaybe<Array<InputMaybe<ComponentFooterColumnInput>>>;
  contact?: InputMaybe<ComponentFooterContactInput>;
  policies?: InputMaybe<ComponentFooterPoliciesInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  socials?: InputMaybe<ComponentFooterSocialsInput>;
};

export type FooterRelationResponseCollection = {
  __typename?: 'FooterRelationResponseCollection';
  data: Array<FooterEntity>;
};

export type GenericMorph =
  | About
  | ComponentAboutLogoCardsSection
  | ComponentAboutMap
  | ComponentAboutTeam
  | ComponentAboutTeamMate
  | ComponentFooterColumn
  | ComponentFooterContact
  | ComponentFooterPolicies
  | ComponentFooterSocials
  | ComponentGetInTouchCheckBox
  | ComponentGetInTouchModal
  | ComponentGetInTouchOption
  | ComponentNavigationContactInfo
  | ComponentNavigationHomeLink
  | ComponentNavigationLink
  | ComponentNavigationOpenMenuColumn
  | ComponentNavigationSocials
  | ComponentPoliciesDivider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesRowItem
  | ComponentPoliciesTableHead
  | ComponentPoliciesTableRow
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | ComponentSharedIconCardsSection
  | ComponentSharedIconSpec
  | ComponentSharedLargeImageSection
  | ComponentSharedMetaSocial
  | ComponentSharedNewsroom
  | ComponentSharedNumbersSection
  | ComponentSharedSeo
  | ComponentSharedTextBlock
  | ComponentSharedTextImageSection
  | ComponentSharedToggleText
  | ComponentSharedTwoImagesGrid
  | ComponentSharedTwoVideosGrid
  | ComponentSharedValuesSection
  | ComponentUiArticle
  | ComponentUiButton
  | ComponentUiCommitment
  | ComponentUiFeature
  | ComponentUiHeading
  | ComponentUiHero
  | ComponentUiHeroImageSection
  | ComponentUiHeroVideoSection
  | ComponentUiIconCard
  | ComponentUiIconSpec
  | ComponentUiImage
  | ComponentUiImageFeatureSection
  | ComponentUiImagesGridItem
  | ComponentUiLogo
  | ComponentUiLogoCard
  | ComponentUiNumbersCard
  | ComponentUiSingleTeamMemberSection
  | ComponentUiTextBlockSection
  | ComponentUiVideo
  | ComponentUiVideoFeatureSection
  | ComponentUiVideosGridItem
  | CookieBanner
  | CookiePolicy
  | CookiesSetting
  | Footer
  | GetInTouch
  | I18NLocale
  | Impressum
  | LegalNotice
  | Navigation
  | PrivacyPolicy
  | SineStack
  | SitemapSitemap
  | SitemapSitemapCache
  | TableRow
  | TranslateBatchTranslateJob
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser
  | WhistleblowingPolicy;

export type GetInTouch = {
  __typename?: 'GetInTouch';
  anchor?: Maybe<Scalars['String']['output']>;
  button?: Maybe<Scalars['String']['output']>;
  checkBox?: Maybe<ComponentGetInTouchCheckBox>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firstLine?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<GetInTouchRelationResponseCollection>;
  modal?: Maybe<ComponentGetInTouchModal>;
  options?: Maybe<Array<Maybe<ComponentGetInTouchOption>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  secondLine?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GetInTouchLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type GetInTouchOptionsArgs = {
  filters?: InputMaybe<ComponentGetInTouchOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GetInTouchEntity = {
  __typename?: 'GetInTouchEntity';
  attributes?: Maybe<GetInTouch>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GetInTouchEntityResponse = {
  __typename?: 'GetInTouchEntityResponse';
  data?: Maybe<GetInTouchEntity>;
};

export type GetInTouchInput = {
  anchor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<Scalars['String']['input']>;
  checkBox?: InputMaybe<ComponentGetInTouchCheckBoxInput>;
  firstLine?: InputMaybe<Scalars['String']['input']>;
  modal?: InputMaybe<ComponentGetInTouchModalInput>;
  options?: InputMaybe<Array<InputMaybe<ComponentGetInTouchOptionInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  secondLine?: InputMaybe<Scalars['String']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GetInTouchRelationResponseCollection = {
  __typename?: 'GetInTouchRelationResponseCollection';
  data: Array<GetInTouchEntity>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type Impressum = {
  __typename?: 'Impressum';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastUpdatedText?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ImpressumRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  text?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImpressumLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ImpressumEntity = {
  __typename?: 'ImpressumEntity';
  attributes?: Maybe<Impressum>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ImpressumEntityResponse = {
  __typename?: 'ImpressumEntityResponse';
  data?: Maybe<ImpressumEntity>;
};

export type ImpressumInput = {
  lastUpdatedText?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ImpressumRelationResponseCollection = {
  __typename?: 'ImpressumRelationResponseCollection';
  data: Array<ImpressumEntity>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type LegalNotice = {
  __typename?: 'LegalNotice';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  legalNotice?: Maybe<Array<Maybe<LegalNoticeLegalNoticeDynamicZone>>>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<LegalNoticeRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  slug_fr?: Maybe<Scalars['String']['output']>;
  slug_hr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LegalNoticeLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type LegalNoticeEntity = {
  __typename?: 'LegalNoticeEntity';
  attributes?: Maybe<LegalNotice>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type LegalNoticeEntityResponse = {
  __typename?: 'LegalNoticeEntityResponse';
  data?: Maybe<LegalNoticeEntity>;
};

export type LegalNoticeInput = {
  legalNotice?: InputMaybe<
    Array<Scalars['LegalNoticeLegalNoticeDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
  slug_fr?: InputMaybe<Scalars['String']['input']>;
  slug_hr?: InputMaybe<Scalars['String']['input']>;
};

export type LegalNoticeLegalNoticeDynamicZone =
  | ComponentPoliciesDivider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type LegalNoticeRelationResponseCollection = {
  __typename?: 'LegalNoticeRelationResponseCollection';
  data: Array<LegalNoticeEntity>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createAboutLocalization?: Maybe<AboutEntityResponse>;
  createCookieBannerLocalization?: Maybe<CookieBannerEntityResponse>;
  createCookiePolicyLocalization?: Maybe<CookiePolicyEntityResponse>;
  createCookiesSettingLocalization?: Maybe<CookiesSettingEntityResponse>;
  createFooterLocalization?: Maybe<FooterEntityResponse>;
  createGetInTouchLocalization?: Maybe<GetInTouchEntityResponse>;
  createImpressumLocalization?: Maybe<ImpressumEntityResponse>;
  createLegalNoticeLocalization?: Maybe<LegalNoticeEntityResponse>;
  createNavigationLocalization?: Maybe<NavigationEntityResponse>;
  createPrivacyPolicyLocalization?: Maybe<PrivacyPolicyEntityResponse>;
  createSineStackLocalization?: Maybe<SineStackEntityResponse>;
  createSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  createSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  createTableRow?: Maybe<TableRowEntityResponse>;
  createTableRowLocalization?: Maybe<TableRowEntityResponse>;
  createTranslateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  createWhistleblowingPolicyLocalization?: Maybe<WhistleblowingPolicyEntityResponse>;
  deleteAbout?: Maybe<AboutEntityResponse>;
  deleteCookieBanner?: Maybe<CookieBannerEntityResponse>;
  deleteCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  deleteCookiesSetting?: Maybe<CookiesSettingEntityResponse>;
  deleteFooter?: Maybe<FooterEntityResponse>;
  deleteGetInTouch?: Maybe<GetInTouchEntityResponse>;
  deleteImpressum?: Maybe<ImpressumEntityResponse>;
  deleteLegalNotice?: Maybe<LegalNoticeEntityResponse>;
  deleteNavigation?: Maybe<NavigationEntityResponse>;
  deletePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  deleteSineStack?: Maybe<SineStackEntityResponse>;
  deleteSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  deleteSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  deleteTableRow?: Maybe<TableRowEntityResponse>;
  deleteTranslateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  sendContactForm?: Maybe<ContactFomResponse>;
  updateAbout?: Maybe<AboutEntityResponse>;
  updateCookieBanner?: Maybe<CookieBannerEntityResponse>;
  updateCookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  updateCookiesSetting?: Maybe<CookiesSettingEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateFooter?: Maybe<FooterEntityResponse>;
  updateGetInTouch?: Maybe<GetInTouchEntityResponse>;
  updateImpressum?: Maybe<ImpressumEntityResponse>;
  updateLegalNotice?: Maybe<LegalNoticeEntityResponse>;
  updateNavigation?: Maybe<NavigationEntityResponse>;
  updatePrivacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  updateSineStack?: Maybe<SineStackEntityResponse>;
  updateSitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  updateSitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  updateTableRow?: Maybe<TableRowEntityResponse>;
  updateTranslateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateWhistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateAboutLocalizationArgs = {
  data?: InputMaybe<AboutInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateCookieBannerLocalizationArgs = {
  data?: InputMaybe<CookieBannerInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateCookiePolicyLocalizationArgs = {
  data?: InputMaybe<CookiePolicyInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateCookiesSettingLocalizationArgs = {
  data?: InputMaybe<CookiesSettingInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateFooterLocalizationArgs = {
  data?: InputMaybe<FooterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateGetInTouchLocalizationArgs = {
  data?: InputMaybe<GetInTouchInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateImpressumLocalizationArgs = {
  data?: InputMaybe<ImpressumInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateLegalNoticeLocalizationArgs = {
  data?: InputMaybe<LegalNoticeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateNavigationLocalizationArgs = {
  data?: InputMaybe<NavigationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreatePrivacyPolicyLocalizationArgs = {
  data?: InputMaybe<PrivacyPolicyInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateSineStackLocalizationArgs = {
  data?: InputMaybe<SineStackInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
};

export type MutationCreateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
};

export type MutationCreateTableRowArgs = {
  data: TableRowInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateTableRowLocalizationArgs = {
  data?: InputMaybe<TableRowInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateTranslateBatchTranslateJobArgs = {
  data: TranslateBatchTranslateJobInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationCreateWhistleblowingPolicyLocalizationArgs = {
  data?: InputMaybe<WhistleblowingPolicyInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteAboutArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteCookieBannerArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteCookiePolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteCookiesSettingArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteFooterArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteGetInTouchArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteImpressumArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteLegalNoticeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteNavigationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeletePrivacyPolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteSineStackArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteSitemapSitemapArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSitemapSitemapCacheArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTableRowArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteTranslateBatchTranslateJobArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteWhistleblowingPolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSendContactFormArgs = {
  data: ContactFormDataInput;
};

export type MutationUpdateAboutArgs = {
  data: AboutInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateCookieBannerArgs = {
  data: CookieBannerInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateCookiePolicyArgs = {
  data: CookiePolicyInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateCookiesSettingArgs = {
  data: CookiesSettingInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateFooterArgs = {
  data: FooterInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateGetInTouchArgs = {
  data: GetInTouchInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateImpressumArgs = {
  data: ImpressumInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateLegalNoticeArgs = {
  data: LegalNoticeInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateNavigationArgs = {
  data: NavigationInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdatePrivacyPolicyArgs = {
  data: PrivacyPolicyInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateSineStackArgs = {
  data: SineStackInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateSitemapSitemapArgs = {
  data: SitemapSitemapInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSitemapSitemapCacheArgs = {
  data: SitemapSitemapCacheInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTableRowArgs = {
  data: TableRowInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateTranslateBatchTranslateJobArgs = {
  data: TranslateBatchTranslateJobInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateWhistleblowingPolicyArgs = {
  data: WhistleblowingPolicyInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Navigation = {
  __typename?: 'Navigation';
  closedMenuLinks?: Maybe<Array<Maybe<ComponentNavigationLink>>>;
  companyContactInfo?: Maybe<ComponentNavigationContactInfo>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  homeLink?: Maybe<ComponentNavigationHomeLink>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<NavigationRelationResponseCollection>;
  openMenuColumns?: Maybe<Array<Maybe<ComponentNavigationOpenMenuColumn>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NavigationClosedMenuLinksArgs = {
  filters?: InputMaybe<ComponentNavigationLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NavigationLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type NavigationOpenMenuColumnsArgs = {
  filters?: InputMaybe<ComponentNavigationOpenMenuColumnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NavigationEntity = {
  __typename?: 'NavigationEntity';
  attributes?: Maybe<Navigation>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type NavigationEntityResponse = {
  __typename?: 'NavigationEntityResponse';
  data?: Maybe<NavigationEntity>;
};

export type NavigationInput = {
  closedMenuLinks?: InputMaybe<Array<InputMaybe<ComponentNavigationLinkInput>>>;
  companyContactInfo?: InputMaybe<ComponentNavigationContactInfoInput>;
  homeLink?: InputMaybe<ComponentNavigationHomeLinkInput>;
  openMenuColumns?: InputMaybe<
    Array<InputMaybe<ComponentNavigationOpenMenuColumnInput>>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NavigationRelationResponseCollection = {
  __typename?: 'NavigationRelationResponseCollection';
  data: Array<NavigationEntity>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type PrivacyPolicy = {
  __typename?: 'PrivacyPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<PrivacyPolicyRelationResponseCollection>;
  policies?: Maybe<Array<Maybe<PrivacyPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  slug_fr?: Maybe<Scalars['String']['output']>;
  slug_hr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PrivacyPolicyLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type PrivacyPolicyEntity = {
  __typename?: 'PrivacyPolicyEntity';
  attributes?: Maybe<PrivacyPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PrivacyPolicyEntityResponse = {
  __typename?: 'PrivacyPolicyEntityResponse';
  data?: Maybe<PrivacyPolicyEntity>;
};

export type PrivacyPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['PrivacyPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
  slug_fr?: InputMaybe<Scalars['String']['input']>;
  slug_hr?: InputMaybe<Scalars['String']['input']>;
};

export type PrivacyPolicyPoliciesDynamicZone =
  | ComponentPoliciesDivider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type PrivacyPolicyRelationResponseCollection = {
  __typename?: 'PrivacyPolicyRelationResponseCollection';
  data: Array<PrivacyPolicyEntity>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  about?: Maybe<AboutEntityResponse>;
  cookieBanner?: Maybe<CookieBannerEntityResponse>;
  cookiePolicy?: Maybe<CookiePolicyEntityResponse>;
  cookiesSetting?: Maybe<CookiesSettingEntityResponse>;
  footer?: Maybe<FooterEntityResponse>;
  getInTouch?: Maybe<GetInTouchEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  impressum?: Maybe<ImpressumEntityResponse>;
  legalNotice?: Maybe<LegalNoticeEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  navigation?: Maybe<NavigationEntityResponse>;
  privacyPolicy?: Maybe<PrivacyPolicyEntityResponse>;
  sineStack?: Maybe<SineStackEntityResponse>;
  sitemapSitemap?: Maybe<SitemapSitemapEntityResponse>;
  sitemapSitemapCache?: Maybe<SitemapSitemapCacheEntityResponse>;
  sitemapSitemapCaches?: Maybe<SitemapSitemapCacheEntityResponseCollection>;
  sitemapSitemaps?: Maybe<SitemapSitemapEntityResponseCollection>;
  tableRow?: Maybe<TableRowEntityResponse>;
  tableRows?: Maybe<TableRowEntityResponseCollection>;
  translateBatchTranslateJob?: Maybe<TranslateBatchTranslateJobEntityResponse>;
  translateBatchTranslateJobs?: Maybe<TranslateBatchTranslateJobEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  whistleblowingPolicy?: Maybe<WhistleblowingPolicyEntityResponse>;
};

export type QueryAboutArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookieBannerArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiePolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryCookiesSettingArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryFooterArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryGetInTouchArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryImpressumArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryLegalNoticeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryNavigationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QueryPrivacyPolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySineStackArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type QuerySitemapSitemapArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCacheArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitemapSitemapCachesArgs = {
  filters?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySitemapSitemapsArgs = {
  filters?: InputMaybe<SitemapSitemapFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTableRowArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryTableRowsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTranslateBatchTranslateJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTranslateBatchTranslateJobsArgs = {
  filters?: InputMaybe<TranslateBatchTranslateJobFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryWhistleblowingPolicyArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type SineStack = {
  __typename?: 'SineStack';
  cardsSection?: Maybe<ComponentSharedIconCardsSection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  heroImageSection?: Maybe<ComponentUiHeroImageSection>;
  imageFeatureSection?: Maybe<Array<Maybe<ComponentUiImageFeatureSection>>>;
  largeImageSection?: Maybe<ComponentSharedLargeImageSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<SineStackRelationResponseCollection>;
  numbersSection?: Maybe<ComponentSharedNumbersSection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  slug_fr?: Maybe<Scalars['String']['output']>;
  slug_hr?: Maybe<Scalars['String']['output']>;
  textImageSection?: Maybe<ComponentSharedTextImageSection>;
  twoVideosGrid?: Maybe<ComponentSharedTwoVideosGrid>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  videoFeatureSection?: Maybe<Array<Maybe<ComponentUiVideoFeatureSection>>>;
};

export type SineStackImageFeatureSectionArgs = {
  filters?: InputMaybe<ComponentUiImageFeatureSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SineStackLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type SineStackVideoFeatureSectionArgs = {
  filters?: InputMaybe<ComponentUiVideoFeatureSectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SineStackEntity = {
  __typename?: 'SineStackEntity';
  attributes?: Maybe<SineStack>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SineStackEntityResponse = {
  __typename?: 'SineStackEntityResponse';
  data?: Maybe<SineStackEntity>;
};

export type SineStackInput = {
  cardsSection?: InputMaybe<ComponentSharedIconCardsSectionInput>;
  heroImageSection?: InputMaybe<ComponentUiHeroImageSectionInput>;
  imageFeatureSection?: InputMaybe<
    Array<InputMaybe<ComponentUiImageFeatureSectionInput>>
  >;
  largeImageSection?: InputMaybe<ComponentSharedLargeImageSectionInput>;
  numbersSection?: InputMaybe<ComponentSharedNumbersSectionInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
  slug_fr?: InputMaybe<Scalars['String']['input']>;
  slug_hr?: InputMaybe<Scalars['String']['input']>;
  textImageSection?: InputMaybe<ComponentSharedTextImageSectionInput>;
  twoVideosGrid?: InputMaybe<ComponentSharedTwoVideosGridInput>;
  videoFeatureSection?: InputMaybe<
    Array<InputMaybe<ComponentUiVideoFeatureSectionInput>>
  >;
};

export type SineStackRelationResponseCollection = {
  __typename?: 'SineStackRelationResponseCollection';
  data: Array<SineStackEntity>;
};

export type SitemapSitemap = {
  __typename?: 'SitemapSitemap';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  delta?: Maybe<Scalars['Int']['output']>;
  link_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  sitemap_string: Scalars['String']['output'];
  type?: Maybe<Enum_Sitemapsitemap_Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCache = {
  __typename?: 'SitemapSitemapCache';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  sitemap_id: Scalars['Int']['output'];
  sitemap_json: Scalars['JSON']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SitemapSitemapCacheEntity = {
  __typename?: 'SitemapSitemapCacheEntity';
  attributes?: Maybe<SitemapSitemapCache>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapCacheEntityResponse = {
  __typename?: 'SitemapSitemapCacheEntityResponse';
  data?: Maybe<SitemapSitemapCacheEntity>;
};

export type SitemapSitemapCacheEntityResponseCollection = {
  __typename?: 'SitemapSitemapCacheEntityResponseCollection';
  data: Array<SitemapSitemapCacheEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapCacheFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapCacheFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapCacheFiltersInput>>>;
  sitemap_id?: InputMaybe<IntFilterInput>;
  sitemap_json?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapCacheInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_id?: InputMaybe<Scalars['Int']['input']>;
  sitemap_json?: InputMaybe<Scalars['JSON']['input']>;
};

export type SitemapSitemapEntity = {
  __typename?: 'SitemapSitemapEntity';
  attributes?: Maybe<SitemapSitemap>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SitemapSitemapEntityResponse = {
  __typename?: 'SitemapSitemapEntityResponse';
  data?: Maybe<SitemapSitemapEntity>;
};

export type SitemapSitemapEntityResponseCollection = {
  __typename?: 'SitemapSitemapEntityResponseCollection';
  data: Array<SitemapSitemapEntity>;
  meta: ResponseCollectionMeta;
};

export type SitemapSitemapFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  delta?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link_count?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SitemapSitemapFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SitemapSitemapFiltersInput>>>;
  sitemap_string?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SitemapSitemapInput = {
  delta?: InputMaybe<Scalars['Int']['input']>;
  link_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sitemap_string?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Sitemapsitemap_Type>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TableRow = {
  __typename?: 'TableRow';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<TableRowRelationResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rows?: Maybe<Array<Maybe<ComponentPoliciesRowItem>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TableRowLocalizationsArgs = {
  filters?: InputMaybe<TableRowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowRowsArgs = {
  filters?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TableRowEntity = {
  __typename?: 'TableRowEntity';
  attributes?: Maybe<TableRow>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TableRowEntityResponse = {
  __typename?: 'TableRowEntityResponse';
  data?: Maybe<TableRowEntity>;
};

export type TableRowEntityResponseCollection = {
  __typename?: 'TableRowEntityResponseCollection';
  data: Array<TableRowEntity>;
  meta: ResponseCollectionMeta;
};

export type TableRowFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<TableRowFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TableRowFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TableRowFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rows?: InputMaybe<ComponentPoliciesRowItemFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TableRowInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rows?: InputMaybe<Array<InputMaybe<ComponentPoliciesRowItemInput>>>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableRowRelationResponseCollection = {
  __typename?: 'TableRowRelationResponseCollection';
  data: Array<TableRowEntity>;
};

export type TranslateBatchTranslateJob = {
  __typename?: 'TranslateBatchTranslateJob';
  autoPublish?: Maybe<Scalars['Boolean']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entityIds?: Maybe<Scalars['JSON']['output']>;
  failureReason?: Maybe<Scalars['JSON']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  sourceLocale?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Enum_Translatebatchtranslatejob_Status>;
  targetLocale?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TranslateBatchTranslateJobEntity = {
  __typename?: 'TranslateBatchTranslateJobEntity';
  attributes?: Maybe<TranslateBatchTranslateJob>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TranslateBatchTranslateJobEntityResponse = {
  __typename?: 'TranslateBatchTranslateJobEntityResponse';
  data?: Maybe<TranslateBatchTranslateJobEntity>;
};

export type TranslateBatchTranslateJobEntityResponseCollection = {
  __typename?: 'TranslateBatchTranslateJobEntityResponseCollection';
  data: Array<TranslateBatchTranslateJobEntity>;
  meta: ResponseCollectionMeta;
};

export type TranslateBatchTranslateJobFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TranslateBatchTranslateJobFiltersInput>>>;
  autoPublish?: InputMaybe<BooleanFilterInput>;
  contentType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  entityIds?: InputMaybe<JsonFilterInput>;
  failureReason?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<TranslateBatchTranslateJobFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TranslateBatchTranslateJobFiltersInput>>>;
  progress?: InputMaybe<FloatFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  sourceLocale?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  targetLocale?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TranslateBatchTranslateJobInput = {
  autoPublish?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  entityIds?: InputMaybe<Scalars['JSON']['input']>;
  failureReason?: InputMaybe<Scalars['JSON']['input']>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  sourceLocale?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Enum_Translatebatchtranslatejob_Status>;
  targetLocale?: InputMaybe<Scalars['String']['input']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  blurhash?: InputMaybe<StringFilterInput>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  blurhash?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type WhistleblowingPolicy = {
  __typename?: 'WhistleblowingPolicy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<WhistleblowingPolicyRelationResponseCollection>;
  policies?: Maybe<Array<Maybe<WhistleblowingPolicyPoliciesDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  slug_de?: Maybe<Scalars['String']['output']>;
  slug_en?: Maybe<Scalars['String']['output']>;
  slug_fr?: Maybe<Scalars['String']['output']>;
  slug_hr?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WhistleblowingPolicyLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type WhistleblowingPolicyEntity = {
  __typename?: 'WhistleblowingPolicyEntity';
  attributes?: Maybe<WhistleblowingPolicy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type WhistleblowingPolicyEntityResponse = {
  __typename?: 'WhistleblowingPolicyEntityResponse';
  data?: Maybe<WhistleblowingPolicyEntity>;
};

export type WhistleblowingPolicyInput = {
  policies?: InputMaybe<
    Array<Scalars['WhistleblowingPolicyPoliciesDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_de?: InputMaybe<Scalars['String']['input']>;
  slug_en?: InputMaybe<Scalars['String']['input']>;
  slug_fr?: InputMaybe<Scalars['String']['input']>;
  slug_hr?: InputMaybe<Scalars['String']['input']>;
};

export type WhistleblowingPolicyPoliciesDynamicZone =
  | ComponentPoliciesDivider
  | ComponentPoliciesDownloadLink
  | ComponentPoliciesMainHeading
  | ComponentPoliciesTableTextContent
  | ComponentPoliciesTitleTextContent
  | Error;

export type WhistleblowingPolicyRelationResponseCollection = {
  __typename?: 'WhistleblowingPolicyRelationResponseCollection';
  data: Array<WhistleblowingPolicyEntity>;
};

export type AboutHeroQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutHeroQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'About';
        heroImageSection?: {
          __typename?: 'ComponentUiHeroImageSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutImageFeatureQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutImageFeatureQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      attributes?: {
        __typename?: 'About';
        imageFeatureSection?: {
          __typename?: 'ComponentUiImageFeatureSection';
          title?: string | null;
          anchor?: string | null;
          richText?: any | null;
          image?: Array<{
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutNumbersQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutNumbersQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      attributes?: {
        __typename?: 'About';
        numbersSection?: {
          __typename?: 'ComponentSharedNumbersSection';
          title?: string | null;
          cards?: Array<{
            __typename?: 'ComponentUiNumbersCard';
            title?: string | null;
            description?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutFacilitySectionQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutFacilitySectionQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      attributes?: {
        __typename?: 'About';
        facilitySection?: {
          __typename?: 'ComponentUiImageFeatureSection';
          title?: string | null;
          anchor?: string | null;
          image?: Array<{
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutSingleTeamMemberSectionQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutSingleTeamMemberSectionQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      attributes?: {
        __typename?: 'About';
        singleTeamMemberSection?: {
          __typename?: 'ComponentUiSingleTeamMemberSection';
          heading?: string | null;
          positionText?: string | null;
          text?: string | null;
          anchor?: string | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutLargeImageQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutLargeImageQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      attributes?: {
        __typename?: 'About';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          title?: string | null;
          anchor?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutNewsroomQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutNewsroomQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      attributes?: {
        __typename?: 'About';
        newsroom?: {
          __typename?: 'ComponentSharedNewsroom';
          title?: string | null;
          anchor?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AboutSeoQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type AboutSeoQuery = {
  __typename?: 'Query';
  about?: {
    __typename?: 'AboutEntityResponse';
    data?: {
      __typename?: 'AboutEntity';
      attributes?: {
        __typename?: 'About';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UploadFileFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename?: 'UploadFileEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'UploadFile';
      url: string;
      alternativeText?: string | null;
      blurhash?: string | null;
    } | null;
  } | null;
};

export type MetaSocialFragment = {
  __typename?: 'ComponentSharedMetaSocial';
  id: string;
  title: string;
  description: string;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  image?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SeoFragment = {
  __typename?: 'ComponentSharedSeo';
  id: string;
  canonicalURL?: string | null;
  keywords?: string | null;
  metaDescription: string;
  metaRobots?: string | null;
  metaTitle: string;
  metaViewport?: string | null;
  structuredData?: any | null;
  metaImage?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  metaSocial?: Array<{
    __typename?: 'ComponentSharedMetaSocial';
    id: string;
    title: string;
    description: string;
    socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
    image?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type HeroVideoSectionFragment = {
  __typename?: 'ComponentUiHeroVideoSection';
  id: string;
  title: string;
  subtitle?: string | null;
  previewVideoLength?: string | null;
  showPreviewVideo?: boolean | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroVideo?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  previewVideo?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type HeroImageSectionFragment = {
  __typename?: 'ComponentUiHeroImageSection';
  id: string;
  title: string;
  subtitle?: string | null;
  previewVideoLength?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  heroImage?: {
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  previewVideo?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type VideoFragment = {
  __typename?: 'ComponentUiVideo';
  autoplay?: boolean | null;
  controls?: boolean | null;
  fullscreen?: boolean | null;
  id: string;
  loop?: boolean | null;
  muted?: boolean | null;
  pauseVideo?: boolean | null;
  poster_alt?: string | null;
  poster?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  mobilePoster?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  video?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ButtonFragment = {
  __typename?: 'ComponentUiButton';
  id: string;
  label: string;
  path?: string | null;
  openInNewTab?: boolean | null;
  file?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type ImageFragment = {
  __typename?: 'ComponentUiImage';
  alt?: string | null;
  id: string;
  img_desktop?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type IconCardFragment = {
  __typename?: 'ComponentUiIconCard';
  description?: string | null;
  id: string;
  title?: string | null;
  icon?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type IconSpecFragment = {
  __typename?: 'ComponentSharedIconSpec';
  text?: string | null;
  icon?: {
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ArticleFragment = {
  __typename?: 'ComponentUiArticle';
  alt?: string | null;
  date?: string | null;
  id: string;
  link_path?: string | null;
  title?: string | null;
  img_desktop?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type HeadingFragment = {
  __typename?: 'ComponentUiHeading';
  title: string;
  subtitle?: string | null;
  description?: string | null;
};

export type HeroFragment = {
  __typename?: 'ComponentUiHero';
  id: string;
  text?: string | null;
  title?: string | null;
  fullVideoLength?: string | null;
  fullVideo?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ImageFeatureSectionFragment = {
  __typename?: 'ComponentUiImageFeatureSection';
  title?: string | null;
  anchor?: string | null;
  richText?: any | null;
  image?: Array<{
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type FacilitySectionFragment = {
  __typename?: 'ComponentUiImageFeatureSection';
  title?: string | null;
  anchor?: string | null;
  image?: Array<{
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type VideoFeatureSectionFragment = {
  __typename?: 'ComponentUiVideoFeatureSection';
  title?: string | null;
  anchor?: string | null;
  richText?: any | null;
  video?: Array<{
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type ImagesGridItemFragment = {
  __typename?: 'ComponentUiImagesGridItem';
  title?: string | null;
  description?: string | null;
  tag?: string | null;
  tagColor?: Enum_Componentuiimagesgriditem_Tagcolor | null;
  alt?: string | null;
  img_desktop?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
  img_mobile?: {
    __typename?: 'UploadFileEntityResponse';
    data?: {
      __typename?: 'UploadFileEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'UploadFile';
        url: string;
        alternativeText?: string | null;
        blurhash?: string | null;
      } | null;
    } | null;
  } | null;
};

export type VideosGridItemFragment = {
  __typename?: 'ComponentUiVideosGridItem';
  title?: string | null;
  description?: string | null;
  tag?: string | null;
  tagColor?: Enum_Componentuivideosgriditem_Tagcolor | null;
  video?: {
    __typename?: 'ComponentUiVideo';
    autoplay?: boolean | null;
    controls?: boolean | null;
    fullscreen?: boolean | null;
    id: string;
    loop?: boolean | null;
    muted?: boolean | null;
    pauseVideo?: boolean | null;
    poster_alt?: string | null;
    poster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    mobilePoster?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    video?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type LargeImageSectionFragment = {
  __typename?: 'ComponentSharedLargeImageSection';
  title?: string | null;
  anchor?: string | null;
  description?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type NavLinkFragment = {
  __typename?: 'ComponentNavigationLink';
  label?: string | null;
  path?: string | null;
  openInNewTab?: boolean | null;
};

export type OpenMenuColumnsFragment = {
  __typename?: 'ComponentNavigationOpenMenuColumn';
  label?: string | null;
  link?: Array<{
    __typename?: 'ComponentNavigationLink';
    label?: string | null;
    path?: string | null;
    openInNewTab?: boolean | null;
  } | null> | null;
  socials?: {
    __typename?: 'ComponentNavigationSocials';
    instagram?: string | null;
    linkedIn?: string | null;
    tiktok?: string | null;
    youtube?: string | null;
  } | null;
};

export type SingleTeamMemberSectionFragment = {
  __typename?: 'ComponentUiSingleTeamMemberSection';
  heading?: string | null;
  positionText?: string | null;
  text?: string | null;
  anchor?: string | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type FooterQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type FooterQuery = {
  __typename?: 'Query';
  footer?: {
    __typename?: 'FooterEntityResponse';
    data?: {
      __typename?: 'FooterEntity';
      attributes?: {
        __typename?: 'Footer';
        column?: Array<{
          __typename?: 'ComponentFooterColumn';
          lable?: string | null;
          link?: Array<{
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
        policies?: {
          __typename?: 'ComponentFooterPolicies';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentUiButton';
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        socials?: {
          __typename?: 'ComponentFooterSocials';
          label?: string | null;
          instagram?: string | null;
          linkenin?: string | null;
          tiktok?: string | null;
          youtube?: string | null;
        } | null;
        contact?: {
          __typename?: 'ComponentFooterContact';
          title?: string | null;
          address?: {
            __typename?: 'ComponentNavigationLink';
            label?: string | null;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
          email?: {
            __typename?: 'ComponentNavigationLink';
            label?: string | null;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type FormQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type FormQuery = {
  __typename?: 'Query';
  getInTouch?: {
    __typename?: 'GetInTouchEntityResponse';
    data?: {
      __typename?: 'GetInTouchEntity';
      attributes?: {
        __typename?: 'GetInTouch';
        anchor?: string | null;
        firstLine?: string | null;
        secondLine?: string | null;
        title?: string | null;
        button?: string | null;
        options?: Array<{
          __typename?: 'ComponentGetInTouchOption';
          value: string;
          label: string;
          isFullWidth: boolean;
          type: Enum_Componentgetintouchoption_Type;
        } | null> | null;
        checkBox?: {
          __typename?: 'ComponentGetInTouchCheckBox';
          labelBefore?: string | null;
          labelLink?: string | null;
          areaLabel?: string | null;
          path?: string | null;
        } | null;
        modal?: {
          __typename?: 'ComponentGetInTouchModal';
          title: string;
          description: string;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type NavigationQuery = {
  __typename?: 'Query';
  navigation?: {
    __typename?: 'NavigationEntityResponse';
    data?: {
      __typename?: 'NavigationEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Navigation';
        closedMenuLinks?: Array<{
          __typename?: 'ComponentNavigationLink';
          label?: string | null;
          path?: string | null;
          openInNewTab?: boolean | null;
        } | null> | null;
        openMenuColumns?: Array<{
          __typename?: 'ComponentNavigationOpenMenuColumn';
          label?: string | null;
          link?: Array<{
            __typename?: 'ComponentNavigationLink';
            label?: string | null;
            path?: string | null;
            openInNewTab?: boolean | null;
          } | null> | null;
          socials?: {
            __typename?: 'ComponentNavigationSocials';
            instagram?: string | null;
            linkedIn?: string | null;
            tiktok?: string | null;
            youtube?: string | null;
          } | null;
        } | null> | null;
        companyContactInfo?: {
          __typename?: 'ComponentNavigationContactInfo';
          name?: string | null;
          address?: string | null;
        } | null;
        homeLink?: {
          __typename?: 'ComponentNavigationHomeLink';
          path?: string | null;
          areaLabel?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ImpressumQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type ImpressumQuery = {
  __typename?: 'Query';
  impressum?: {
    __typename?: 'ImpressumEntityResponse';
    data?: {
      __typename?: 'ImpressumEntity';
      attributes?: {
        __typename?: 'Impressum';
        title?: string | null;
        text?: any | null;
        lastUpdatedText?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SendContactFormMutationVariables = Exact<{
  data: ContactFormDataInput;
}>;

export type SendContactFormMutation = {
  __typename?: 'Mutation';
  sendContactForm?: {
    __typename?: 'ContactFomResponse';
    success?: boolean | null;
  } | null;
};

type PolicyFields_ComponentPoliciesDivider_Fragment = {
  __typename?: 'ComponentPoliciesDivider';
  Divider: boolean;
};

type PolicyFields_ComponentPoliciesDownloadLink_Fragment = {
  __typename?: 'ComponentPoliciesDownloadLink';
  label?: string | null;
  link?: string | null;
};

type PolicyFields_ComponentPoliciesMainHeading_Fragment = {
  __typename?: 'ComponentPoliciesMainHeading';
  text?: any | null;
};

type PolicyFields_ComponentPoliciesTableTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTableTextContent';
  richText?: any | null;
  tableHead?: Array<{
    __typename?: 'ComponentPoliciesTableHead';
    headingItem?: string | null;
  } | null> | null;
  tableRow?: {
    __typename?: 'ComponentPoliciesTableRow';
    table_rows?: {
      __typename?: 'TableRowRelationResponseCollection';
      data: Array<{
        __typename?: 'TableRowEntity';
        attributes?: {
          __typename?: 'TableRow';
          rows?: Array<{
            __typename?: 'ComponentPoliciesRowItem';
            item?: string | null;
          } | null> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

type PolicyFields_ComponentPoliciesTitleTextContent_Fragment = {
  __typename?: 'ComponentPoliciesTitleTextContent';
  text?: any | null;
};

type PolicyFields_Error_Fragment = { __typename?: 'Error' };

export type PolicyFieldsFragment =
  | PolicyFields_ComponentPoliciesDivider_Fragment
  | PolicyFields_ComponentPoliciesDownloadLink_Fragment
  | PolicyFields_ComponentPoliciesMainHeading_Fragment
  | PolicyFields_ComponentPoliciesTableTextContent_Fragment
  | PolicyFields_ComponentPoliciesTitleTextContent_Fragment
  | PolicyFields_Error_Fragment;

export type PrivacyPolicyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type PrivacyPolicyQuery = {
  __typename?: 'Query';
  privacyPolicy?: {
    __typename?: 'PrivacyPolicyEntityResponse';
    data?: {
      __typename?: 'PrivacyPolicyEntity';
      attributes?: {
        __typename?: 'PrivacyPolicy';
        policies?: Array<
          | { __typename?: 'ComponentPoliciesDivider'; Divider: boolean }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHead';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type LegalNoticeQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type LegalNoticeQuery = {
  __typename?: 'Query';
  legalNotice?: {
    __typename?: 'LegalNoticeEntityResponse';
    data?: {
      __typename?: 'LegalNoticeEntity';
      attributes?: {
        __typename?: 'LegalNotice';
        legalNotice?: Array<
          | { __typename?: 'ComponentPoliciesDivider'; Divider: boolean }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHead';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiePolicyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type CookiePolicyQuery = {
  __typename?: 'Query';
  cookiePolicy?: {
    __typename?: 'CookiePolicyEntityResponse';
    data?: {
      __typename?: 'CookiePolicyEntity';
      attributes?: {
        __typename?: 'CookiePolicy';
        cookiePolicy?: Array<
          | { __typename?: 'ComponentPoliciesDivider'; Divider: boolean }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHead';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type WhistleblowingPolicyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type WhistleblowingPolicyQuery = {
  __typename?: 'Query';
  whistleblowingPolicy?: {
    __typename?: 'WhistleblowingPolicyEntityResponse';
    data?: {
      __typename?: 'WhistleblowingPolicyEntity';
      attributes?: {
        __typename?: 'WhistleblowingPolicy';
        policies?: Array<
          | { __typename?: 'ComponentPoliciesDivider'; Divider: boolean }
          | {
              __typename?: 'ComponentPoliciesDownloadLink';
              label?: string | null;
              link?: string | null;
            }
          | { __typename?: 'ComponentPoliciesMainHeading'; text?: any | null }
          | {
              __typename?: 'ComponentPoliciesTableTextContent';
              richText?: any | null;
              tableHead?: Array<{
                __typename?: 'ComponentPoliciesTableHead';
                headingItem?: string | null;
              } | null> | null;
              tableRow?: {
                __typename?: 'ComponentPoliciesTableRow';
                table_rows?: {
                  __typename?: 'TableRowRelationResponseCollection';
                  data: Array<{
                    __typename?: 'TableRowEntity';
                    attributes?: {
                      __typename?: 'TableRow';
                      rows?: Array<{
                        __typename?: 'ComponentPoliciesRowItem';
                        item?: string | null;
                      } | null> | null;
                    } | null;
                  }>;
                } | null;
              } | null;
            }
          | {
              __typename?: 'ComponentPoliciesTitleTextContent';
              text?: any | null;
            }
          | { __typename?: 'Error' }
          | null
        > | null;
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesSettingsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type CookiesSettingsQuery = {
  __typename?: 'Query';
  cookiesSetting?: {
    __typename?: 'CookiesSettingEntityResponse';
    data?: {
      __typename?: 'CookiesSettingEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookiesSetting';
        actionButtonLabel?: string | null;
        necessaryCookiesHeading?: string | null;
        optionalCookiesHeading?: string | null;
        title?: string | null;
        necessaryCookiesTable?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHead';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        optionalCookiesTable?: {
          __typename?: 'ComponentPoliciesTableTextContent';
          richText?: any | null;
          tableHead?: Array<{
            __typename?: 'ComponentPoliciesTableHead';
            headingItem?: string | null;
          } | null> | null;
          tableRow?: {
            __typename?: 'ComponentPoliciesTableRow';
            table_rows?: {
              __typename?: 'TableRowRelationResponseCollection';
              data: Array<{
                __typename?: 'TableRowEntity';
                attributes?: {
                  __typename?: 'TableRow';
                  rows?: Array<{
                    __typename?: 'ComponentPoliciesRowItem';
                    item?: string | null;
                  } | null> | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
        redirectButton?: {
          __typename?: 'ComponentUiButton';
          label: string;
          path?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CookiesBarQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type CookiesBarQuery = {
  __typename?: 'Query';
  cookieBanner?: {
    __typename?: 'CookieBannerEntityResponse';
    data?: {
      __typename?: 'CookieBannerEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'CookieBanner';
        heading?: string | null;
        editButtonLabel?: string | null;
        declineButtonLabel?: string | null;
        acceptButtonLabel?: string | null;
        text?: any | null;
        extraLinks?: Array<{
          __typename?: 'ComponentUiButton';
          id: string;
          label: string;
          path?: string | null;
          openInNewTab?: boolean | null;
          file?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        toggleText?: {
          __typename?: 'ComponentSharedToggleText';
          firstText: string;
          secondText: string;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type NumbersSectionFragment = {
  __typename?: 'ComponentSharedNumbersSection';
  title?: string | null;
  cards?: Array<{
    __typename?: 'ComponentUiNumbersCard';
    title?: string | null;
    description?: string | null;
    value?: string | null;
  } | null> | null;
};

export type TextBlockFragment = {
  __typename?: 'ComponentSharedTextBlock';
  heading?: {
    __typename?: 'ComponentUiHeading';
    title: string;
    subtitle?: string | null;
    description?: string | null;
  } | null;
  sections?: Array<{
    __typename?: 'ComponentUiTextBlockSection';
    title: string;
    text?: any | null;
    icon?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ValuesFragment = {
  __typename?: 'ComponentSharedValuesSection';
  title?: string | null;
  anchor?: string | null;
  cards?: Array<{
    __typename?: 'ComponentUiIconCard';
    description?: string | null;
    id: string;
    title?: string | null;
    icon?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type TwoImagesGridFragment = {
  __typename?: 'ComponentSharedTwoImagesGrid';
  title?: string | null;
  imagesGridItems?: Array<{
    __typename?: 'ComponentUiImagesGridItem';
    title?: string | null;
    description?: string | null;
    tag?: string | null;
    tagColor?: Enum_Componentuiimagesgriditem_Tagcolor | null;
    alt?: string | null;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type TwoVideosGridFragment = {
  __typename?: 'ComponentSharedTwoVideosGrid';
  title?: string | null;
  videosGridItems?: Array<{
    __typename?: 'ComponentUiVideosGridItem';
    title?: string | null;
    description?: string | null;
    tag?: string | null;
    tagColor?: Enum_Componentuivideosgriditem_Tagcolor | null;
    video?: {
      __typename?: 'ComponentUiVideo';
      autoplay?: boolean | null;
      controls?: boolean | null;
      fullscreen?: boolean | null;
      id: string;
      loop?: boolean | null;
      muted?: boolean | null;
      pauseVideo?: boolean | null;
      poster_alt?: string | null;
      poster?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
      mobilePoster?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
      video?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CardsSectionFragment = {
  __typename?: 'ComponentSharedIconCardsSection';
  title?: string | null;
  anchor?: string | null;
  subtitle?: string | null;
  iconCard?: Array<{
    __typename?: 'ComponentUiIconCard';
    description?: string | null;
    id: string;
    title?: string | null;
    icon?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type NewsroomFragment = {
  __typename?: 'ComponentSharedNewsroom';
  title?: string | null;
  anchor?: string | null;
  button?: {
    __typename?: 'ComponentUiButton';
    id: string;
    label: string;
    path?: string | null;
    openInNewTab?: boolean | null;
    file?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type TextImageSectionFragment = {
  __typename?: 'ComponentSharedTextImageSection';
  anchor?: string | null;
  title?: string | null;
  text?: any | null;
  image?: {
    __typename?: 'ComponentUiImage';
    alt?: string | null;
    id: string;
    img_desktop?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
    img_mobile?: {
      __typename?: 'UploadFileEntityResponse';
      data?: {
        __typename?: 'UploadFileEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'UploadFile';
          url: string;
          alternativeText?: string | null;
          blurhash?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  iconSpec?: Array<{
    __typename?: 'ComponentSharedIconSpec';
    text?: string | null;
    icon?: {
      __typename?: 'ComponentUiImage';
      alt?: string | null;
      id: string;
      img_desktop?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
      img_mobile?: {
        __typename?: 'UploadFileEntityResponse';
        data?: {
          __typename?: 'UploadFileEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'UploadFile';
            url: string;
            alternativeText?: string | null;
            blurhash?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type SineStackHeroQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackHeroQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'SineStack';
        heroImageSection?: {
          __typename?: 'ComponentUiHeroImageSection';
          id: string;
          title: string;
          subtitle?: string | null;
          previewVideoLength?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          heroImage?: {
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          previewVideo?: {
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackCardsSectionQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackCardsSectionQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        cardsSection?: {
          __typename?: 'ComponentSharedIconCardsSection';
          title?: string | null;
          anchor?: string | null;
          subtitle?: string | null;
          iconCard?: Array<{
            __typename?: 'ComponentUiIconCard';
            description?: string | null;
            id: string;
            title?: string | null;
            icon?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackNumbersQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackNumbersQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        numbersSection?: {
          __typename?: 'ComponentSharedNumbersSection';
          title?: string | null;
          cards?: Array<{
            __typename?: 'ComponentUiNumbersCard';
            title?: string | null;
            description?: string | null;
            value?: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackVideoFeatureQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackVideoFeatureQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        videoFeatureSection?: Array<{
          __typename?: 'ComponentUiVideoFeatureSection';
          title?: string | null;
          anchor?: string | null;
          richText?: any | null;
          video?: Array<{
            __typename?: 'ComponentUiVideo';
            autoplay?: boolean | null;
            controls?: boolean | null;
            fullscreen?: boolean | null;
            id: string;
            loop?: boolean | null;
            muted?: boolean | null;
            pauseVideo?: boolean | null;
            poster_alt?: string | null;
            poster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            mobilePoster?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            video?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackImageFeatureQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackImageFeatureQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        imageFeatureSection?: Array<{
          __typename?: 'ComponentUiImageFeatureSection';
          title?: string | null;
          anchor?: string | null;
          richText?: any | null;
          image?: Array<{
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackTextImageSectionQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackTextImageSectionQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        textImageSection?: {
          __typename?: 'ComponentSharedTextImageSection';
          anchor?: string | null;
          title?: string | null;
          text?: any | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          iconSpec?: Array<{
            __typename?: 'ComponentSharedIconSpec';
            text?: string | null;
            icon?: {
              __typename?: 'ComponentUiImage';
              alt?: string | null;
              id: string;
              img_desktop?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
              img_mobile?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackTwoVideosGridQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackTwoVideosGridQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        twoVideosGrid?: {
          __typename?: 'ComponentSharedTwoVideosGrid';
          title?: string | null;
          videosGridItems?: Array<{
            __typename?: 'ComponentUiVideosGridItem';
            title?: string | null;
            description?: string | null;
            tag?: string | null;
            tagColor?: Enum_Componentuivideosgriditem_Tagcolor | null;
            video?: {
              __typename?: 'ComponentUiVideo';
              autoplay?: boolean | null;
              controls?: boolean | null;
              fullscreen?: boolean | null;
              id: string;
              loop?: boolean | null;
              muted?: boolean | null;
              pauseVideo?: boolean | null;
              poster_alt?: string | null;
              poster?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
              mobilePoster?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
              video?: {
                __typename?: 'UploadFileEntityResponse';
                data?: {
                  __typename?: 'UploadFileEntity';
                  id?: string | null;
                  attributes?: {
                    __typename?: 'UploadFile';
                    url: string;
                    alternativeText?: string | null;
                    blurhash?: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackLargeImageQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackLargeImageQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        largeImageSection?: {
          __typename?: 'ComponentSharedLargeImageSection';
          title?: string | null;
          anchor?: string | null;
          description?: string | null;
          button?: {
            __typename?: 'ComponentUiButton';
            id: string;
            label: string;
            path?: string | null;
            openInNewTab?: boolean | null;
            file?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
          image?: {
            __typename?: 'ComponentUiImage';
            alt?: string | null;
            id: string;
            img_desktop?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
            img_mobile?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type SineStackSeoQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}>;

export type SineStackSeoQuery = {
  __typename?: 'Query';
  sineStack?: {
    __typename?: 'SineStackEntityResponse';
    data?: {
      __typename?: 'SineStackEntity';
      attributes?: {
        __typename?: 'SineStack';
        seo?: {
          __typename?: 'ComponentSharedSeo';
          id: string;
          canonicalURL?: string | null;
          keywords?: string | null;
          metaDescription: string;
          metaRobots?: string | null;
          metaTitle: string;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage?: {
            __typename?: 'UploadFileEntityResponse';
            data?: {
              __typename?: 'UploadFileEntity';
              id?: string | null;
              attributes?: {
                __typename?: 'UploadFile';
                url: string;
                alternativeText?: string | null;
                blurhash?: string | null;
              } | null;
            } | null;
          } | null;
          metaSocial?: Array<{
            __typename?: 'ComponentSharedMetaSocial';
            id: string;
            title: string;
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            image?: {
              __typename?: 'UploadFileEntityResponse';
              data?: {
                __typename?: 'UploadFileEntity';
                id?: string | null;
                attributes?: {
                  __typename?: 'UploadFile';
                  url: string;
                  alternativeText?: string | null;
                  blurhash?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const UploadFileFragmentDoc = gql`
  fragment uploadFile on UploadFileEntityResponse {
    data {
      id
      attributes {
        url
        alternativeText
        blurhash
      }
    }
  }
`;
export const MetaSocialFragmentDoc = gql`
  fragment metaSocial on ComponentSharedMetaSocial {
    id
    title
    description
    socialNetwork
    image {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const SeoFragmentDoc = gql`
  fragment seo on ComponentSharedSeo {
    id
    canonicalURL
    keywords
    metaDescription
    metaImage {
      ...uploadFile
    }
    metaRobots
    metaSocial {
      ...metaSocial
    }
    metaTitle
    metaViewport
    structuredData
  }
  ${UploadFileFragmentDoc}
  ${MetaSocialFragmentDoc}
`;
export const ButtonFragmentDoc = gql`
  fragment button on ComponentUiButton {
    id
    label
    path
    openInNewTab
    file {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const VideoFragmentDoc = gql`
  fragment video on ComponentUiVideo {
    autoplay
    controls
    fullscreen
    id
    loop
    muted
    pauseVideo
    poster {
      ...uploadFile
    }
    mobilePoster {
      ...uploadFile
    }
    poster_alt
    video {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const HeroVideoSectionFragmentDoc = gql`
  fragment heroVideoSection on ComponentUiHeroVideoSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroVideo {
      ...video
    }
    previewVideo {
      ...video
    }
    previewVideoLength
    showPreviewVideo
  }
  ${ButtonFragmentDoc}
  ${VideoFragmentDoc}
`;
export const ImageFragmentDoc = gql`
  fragment image on ComponentUiImage {
    alt
    id
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const HeroImageSectionFragmentDoc = gql`
  fragment heroImageSection on ComponentUiHeroImageSection {
    id
    title
    subtitle
    button {
      ...button
    }
    heroImage {
      ...image
    }
    previewVideo {
      ...video
    }
    previewVideoLength
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
  ${VideoFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
  fragment article on ComponentUiArticle {
    alt
    date
    id
    link_path
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
    title
  }
  ${UploadFileFragmentDoc}
`;
export const HeroFragmentDoc = gql`
  fragment hero on ComponentUiHero {
    id
    text
    title
    fullVideoLength
    fullVideo {
      ...video
    }
    image {
      ...image
    }
  }
  ${VideoFragmentDoc}
  ${ImageFragmentDoc}
`;
export const ImageFeatureSectionFragmentDoc = gql`
  fragment imageFeatureSection on ComponentUiImageFeatureSection {
    title
    anchor
    richText
    image {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const FacilitySectionFragmentDoc = gql`
  fragment facilitySection on ComponentUiImageFeatureSection {
    title
    anchor
    image {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const VideoFeatureSectionFragmentDoc = gql`
  fragment videoFeatureSection on ComponentUiVideoFeatureSection {
    title
    anchor
    richText
    video {
      ...video
    }
  }
  ${VideoFragmentDoc}
`;
export const LargeImageSectionFragmentDoc = gql`
  fragment largeImageSection on ComponentSharedLargeImageSection {
    title
    anchor
    description
    button {
      ...button
    }
    image {
      ...image
    }
  }
  ${ButtonFragmentDoc}
  ${ImageFragmentDoc}
`;
export const NavLinkFragmentDoc = gql`
  fragment navLink on ComponentNavigationLink {
    label
    path
    openInNewTab
  }
`;
export const OpenMenuColumnsFragmentDoc = gql`
  fragment openMenuColumns on ComponentNavigationOpenMenuColumn {
    label
    link {
      ...navLink
    }
    socials {
      instagram
      linkedIn
      tiktok
      youtube
    }
  }
  ${NavLinkFragmentDoc}
`;
export const SingleTeamMemberSectionFragmentDoc = gql`
  fragment singleTeamMemberSection on ComponentUiSingleTeamMemberSection {
    heading
    positionText
    text
    image {
      ...image
    }
    anchor
  }
  ${ImageFragmentDoc}
`;
export const PolicyFieldsFragmentDoc = gql`
  fragment policyFields on PrivacyPolicyPoliciesDynamicZone {
    ... on ComponentPoliciesDownloadLink {
      label
      link
    }
    ... on ComponentPoliciesMainHeading {
      text
    }
    ... on ComponentPoliciesTitleTextContent {
      text
    }
    ... on ComponentPoliciesTableTextContent {
      richText
      tableHead {
        headingItem
      }
      tableRow {
        table_rows {
          data {
            attributes {
              rows {
                item
              }
            }
          }
        }
      }
    }
    ... on ComponentPoliciesDivider {
      Divider
    }
  }
`;
export const NumbersSectionFragmentDoc = gql`
  fragment numbersSection on ComponentSharedNumbersSection {
    title
    cards {
      title
      description
      value
    }
  }
`;
export const HeadingFragmentDoc = gql`
  fragment heading on ComponentUiHeading {
    title
    subtitle
    description
  }
`;
export const TextBlockFragmentDoc = gql`
  fragment textBlock on ComponentSharedTextBlock {
    heading {
      ...heading
    }
    sections {
      title
      text
      icon {
        ...uploadFile
      }
    }
    image {
      ...image
    }
  }
  ${HeadingFragmentDoc}
  ${UploadFileFragmentDoc}
  ${ImageFragmentDoc}
`;
export const IconCardFragmentDoc = gql`
  fragment iconCard on ComponentUiIconCard {
    description
    icon {
      ...uploadFile
    }
    id
    title
  }
  ${UploadFileFragmentDoc}
`;
export const ValuesFragmentDoc = gql`
  fragment values on ComponentSharedValuesSection {
    title
    anchor
    cards {
      ...iconCard
    }
  }
  ${IconCardFragmentDoc}
`;
export const ImagesGridItemFragmentDoc = gql`
  fragment imagesGridItem on ComponentUiImagesGridItem {
    title
    description
    tag
    tagColor
    alt
    img_desktop {
      ...uploadFile
    }
    img_mobile {
      ...uploadFile
    }
  }
  ${UploadFileFragmentDoc}
`;
export const TwoImagesGridFragmentDoc = gql`
  fragment twoImagesGrid on ComponentSharedTwoImagesGrid {
    title
    imagesGridItems {
      ...imagesGridItem
    }
  }
  ${ImagesGridItemFragmentDoc}
`;
export const VideosGridItemFragmentDoc = gql`
  fragment videosGridItem on ComponentUiVideosGridItem {
    title
    description
    tag
    tagColor
    video {
      ...video
    }
  }
  ${VideoFragmentDoc}
`;
export const TwoVideosGridFragmentDoc = gql`
  fragment twoVideosGrid on ComponentSharedTwoVideosGrid {
    title
    videosGridItems {
      ...videosGridItem
    }
  }
  ${VideosGridItemFragmentDoc}
`;
export const CardsSectionFragmentDoc = gql`
  fragment cardsSection on ComponentSharedIconCardsSection {
    title
    anchor
    subtitle
    iconCard {
      ...iconCard
    }
  }
  ${IconCardFragmentDoc}
`;
export const NewsroomFragmentDoc = gql`
  fragment newsroom on ComponentSharedNewsroom {
    title
    anchor
    button {
      ...button
    }
  }
  ${ButtonFragmentDoc}
`;
export const IconSpecFragmentDoc = gql`
  fragment iconSpec on ComponentSharedIconSpec {
    text
    icon {
      ...image
    }
  }
  ${ImageFragmentDoc}
`;
export const TextImageSectionFragmentDoc = gql`
  fragment textImageSection on ComponentSharedTextImageSection {
    anchor
    title
    text
    image {
      ...image
    }
    iconSpec {
      ...iconSpec
    }
  }
  ${ImageFragmentDoc}
  ${IconSpecFragmentDoc}
`;
export const AboutHeroDocument = gql`
  query AboutHero($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useAboutHeroQuery__
 *
 * To run a query within a React component, call `useAboutHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutHeroQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutHeroQuery,
    AboutHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutHeroQuery, AboutHeroQueryVariables>(
    AboutHeroDocument,
    options,
  );
}
export function useAboutHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutHeroQuery,
    AboutHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AboutHeroQuery, AboutHeroQueryVariables>(
    AboutHeroDocument,
    options,
  );
}
export function useAboutHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AboutHeroQuery, AboutHeroQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AboutHeroQuery, AboutHeroQueryVariables>(
    AboutHeroDocument,
    options,
  );
}
export type AboutHeroQueryHookResult = ReturnType<typeof useAboutHeroQuery>;
export type AboutHeroLazyQueryHookResult = ReturnType<
  typeof useAboutHeroLazyQuery
>;
export type AboutHeroSuspenseQueryHookResult = ReturnType<
  typeof useAboutHeroSuspenseQuery
>;
export type AboutHeroQueryResult = Apollo.QueryResult<
  AboutHeroQuery,
  AboutHeroQueryVariables
>;
export const AboutImageFeatureDocument = gql`
  query AboutImageFeature($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        attributes {
          imageFeatureSection {
            ...imageFeatureSection
          }
        }
      }
    }
  }
  ${ImageFeatureSectionFragmentDoc}
`;

/**
 * __useAboutImageFeatureQuery__
 *
 * To run a query within a React component, call `useAboutImageFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutImageFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutImageFeatureQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutImageFeatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutImageFeatureQuery,
    AboutImageFeatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AboutImageFeatureQuery,
    AboutImageFeatureQueryVariables
  >(AboutImageFeatureDocument, options);
}
export function useAboutImageFeatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutImageFeatureQuery,
    AboutImageFeatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutImageFeatureQuery,
    AboutImageFeatureQueryVariables
  >(AboutImageFeatureDocument, options);
}
export function useAboutImageFeatureSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutImageFeatureQuery,
        AboutImageFeatureQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutImageFeatureQuery,
    AboutImageFeatureQueryVariables
  >(AboutImageFeatureDocument, options);
}
export type AboutImageFeatureQueryHookResult = ReturnType<
  typeof useAboutImageFeatureQuery
>;
export type AboutImageFeatureLazyQueryHookResult = ReturnType<
  typeof useAboutImageFeatureLazyQuery
>;
export type AboutImageFeatureSuspenseQueryHookResult = ReturnType<
  typeof useAboutImageFeatureSuspenseQuery
>;
export type AboutImageFeatureQueryResult = Apollo.QueryResult<
  AboutImageFeatureQuery,
  AboutImageFeatureQueryVariables
>;
export const AboutNumbersDocument = gql`
  query AboutNumbers($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        attributes {
          numbersSection {
            ...numbersSection
          }
        }
      }
    }
  }
  ${NumbersSectionFragmentDoc}
`;

/**
 * __useAboutNumbersQuery__
 *
 * To run a query within a React component, call `useAboutNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutNumbersQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutNumbersQuery,
    AboutNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutNumbersQuery, AboutNumbersQueryVariables>(
    AboutNumbersDocument,
    options,
  );
}
export function useAboutNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutNumbersQuery,
    AboutNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AboutNumbersQuery, AboutNumbersQueryVariables>(
    AboutNumbersDocument,
    options,
  );
}
export function useAboutNumbersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutNumbersQuery,
        AboutNumbersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AboutNumbersQuery, AboutNumbersQueryVariables>(
    AboutNumbersDocument,
    options,
  );
}
export type AboutNumbersQueryHookResult = ReturnType<
  typeof useAboutNumbersQuery
>;
export type AboutNumbersLazyQueryHookResult = ReturnType<
  typeof useAboutNumbersLazyQuery
>;
export type AboutNumbersSuspenseQueryHookResult = ReturnType<
  typeof useAboutNumbersSuspenseQuery
>;
export type AboutNumbersQueryResult = Apollo.QueryResult<
  AboutNumbersQuery,
  AboutNumbersQueryVariables
>;
export const AboutFacilitySectionDocument = gql`
  query AboutFacilitySection($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        attributes {
          facilitySection {
            ...facilitySection
          }
        }
      }
    }
  }
  ${FacilitySectionFragmentDoc}
`;

/**
 * __useAboutFacilitySectionQuery__
 *
 * To run a query within a React component, call `useAboutFacilitySectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutFacilitySectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutFacilitySectionQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutFacilitySectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutFacilitySectionQuery,
    AboutFacilitySectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AboutFacilitySectionQuery,
    AboutFacilitySectionQueryVariables
  >(AboutFacilitySectionDocument, options);
}
export function useAboutFacilitySectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutFacilitySectionQuery,
    AboutFacilitySectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutFacilitySectionQuery,
    AboutFacilitySectionQueryVariables
  >(AboutFacilitySectionDocument, options);
}
export function useAboutFacilitySectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutFacilitySectionQuery,
        AboutFacilitySectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutFacilitySectionQuery,
    AboutFacilitySectionQueryVariables
  >(AboutFacilitySectionDocument, options);
}
export type AboutFacilitySectionQueryHookResult = ReturnType<
  typeof useAboutFacilitySectionQuery
>;
export type AboutFacilitySectionLazyQueryHookResult = ReturnType<
  typeof useAboutFacilitySectionLazyQuery
>;
export type AboutFacilitySectionSuspenseQueryHookResult = ReturnType<
  typeof useAboutFacilitySectionSuspenseQuery
>;
export type AboutFacilitySectionQueryResult = Apollo.QueryResult<
  AboutFacilitySectionQuery,
  AboutFacilitySectionQueryVariables
>;
export const AboutSingleTeamMemberSectionDocument = gql`
  query AboutSingleTeamMemberSection($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        attributes {
          singleTeamMemberSection {
            ...singleTeamMemberSection
          }
        }
      }
    }
  }
  ${SingleTeamMemberSectionFragmentDoc}
`;

/**
 * __useAboutSingleTeamMemberSectionQuery__
 *
 * To run a query within a React component, call `useAboutSingleTeamMemberSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutSingleTeamMemberSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutSingleTeamMemberSectionQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutSingleTeamMemberSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutSingleTeamMemberSectionQuery,
    AboutSingleTeamMemberSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AboutSingleTeamMemberSectionQuery,
    AboutSingleTeamMemberSectionQueryVariables
  >(AboutSingleTeamMemberSectionDocument, options);
}
export function useAboutSingleTeamMemberSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutSingleTeamMemberSectionQuery,
    AboutSingleTeamMemberSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutSingleTeamMemberSectionQuery,
    AboutSingleTeamMemberSectionQueryVariables
  >(AboutSingleTeamMemberSectionDocument, options);
}
export function useAboutSingleTeamMemberSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutSingleTeamMemberSectionQuery,
        AboutSingleTeamMemberSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutSingleTeamMemberSectionQuery,
    AboutSingleTeamMemberSectionQueryVariables
  >(AboutSingleTeamMemberSectionDocument, options);
}
export type AboutSingleTeamMemberSectionQueryHookResult = ReturnType<
  typeof useAboutSingleTeamMemberSectionQuery
>;
export type AboutSingleTeamMemberSectionLazyQueryHookResult = ReturnType<
  typeof useAboutSingleTeamMemberSectionLazyQuery
>;
export type AboutSingleTeamMemberSectionSuspenseQueryHookResult = ReturnType<
  typeof useAboutSingleTeamMemberSectionSuspenseQuery
>;
export type AboutSingleTeamMemberSectionQueryResult = Apollo.QueryResult<
  AboutSingleTeamMemberSectionQuery,
  AboutSingleTeamMemberSectionQueryVariables
>;
export const AboutLargeImageDocument = gql`
  query AboutLargeImage($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useAboutLargeImageQuery__
 *
 * To run a query within a React component, call `useAboutLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutLargeImageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutLargeImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutLargeImageQuery,
    AboutLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutLargeImageQuery, AboutLargeImageQueryVariables>(
    AboutLargeImageDocument,
    options,
  );
}
export function useAboutLargeImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutLargeImageQuery,
    AboutLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AboutLargeImageQuery,
    AboutLargeImageQueryVariables
  >(AboutLargeImageDocument, options);
}
export function useAboutLargeImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutLargeImageQuery,
        AboutLargeImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutLargeImageQuery,
    AboutLargeImageQueryVariables
  >(AboutLargeImageDocument, options);
}
export type AboutLargeImageQueryHookResult = ReturnType<
  typeof useAboutLargeImageQuery
>;
export type AboutLargeImageLazyQueryHookResult = ReturnType<
  typeof useAboutLargeImageLazyQuery
>;
export type AboutLargeImageSuspenseQueryHookResult = ReturnType<
  typeof useAboutLargeImageSuspenseQuery
>;
export type AboutLargeImageQueryResult = Apollo.QueryResult<
  AboutLargeImageQuery,
  AboutLargeImageQueryVariables
>;
export const AboutNewsroomDocument = gql`
  query AboutNewsroom($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        attributes {
          newsroom {
            ...newsroom
          }
        }
      }
    }
  }
  ${NewsroomFragmentDoc}
`;

/**
 * __useAboutNewsroomQuery__
 *
 * To run a query within a React component, call `useAboutNewsroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutNewsroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutNewsroomQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutNewsroomQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AboutNewsroomQuery,
    AboutNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutNewsroomQuery, AboutNewsroomQueryVariables>(
    AboutNewsroomDocument,
    options,
  );
}
export function useAboutNewsroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutNewsroomQuery,
    AboutNewsroomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AboutNewsroomQuery, AboutNewsroomQueryVariables>(
    AboutNewsroomDocument,
    options,
  );
}
export function useAboutNewsroomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AboutNewsroomQuery,
        AboutNewsroomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AboutNewsroomQuery,
    AboutNewsroomQueryVariables
  >(AboutNewsroomDocument, options);
}
export type AboutNewsroomQueryHookResult = ReturnType<
  typeof useAboutNewsroomQuery
>;
export type AboutNewsroomLazyQueryHookResult = ReturnType<
  typeof useAboutNewsroomLazyQuery
>;
export type AboutNewsroomSuspenseQueryHookResult = ReturnType<
  typeof useAboutNewsroomSuspenseQuery
>;
export type AboutNewsroomQueryResult = Apollo.QueryResult<
  AboutNewsroomQuery,
  AboutNewsroomQueryVariables
>;
export const AboutSeoDocument = gql`
  query AboutSeo($locale: I18NLocaleCode) {
    about(locale: $locale) {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useAboutSeoQuery__
 *
 * To run a query within a React component, call `useAboutSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutSeoQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAboutSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<AboutSeoQuery, AboutSeoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AboutSeoQuery, AboutSeoQueryVariables>(
    AboutSeoDocument,
    options,
  );
}
export function useAboutSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AboutSeoQuery,
    AboutSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AboutSeoQuery, AboutSeoQueryVariables>(
    AboutSeoDocument,
    options,
  );
}
export function useAboutSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AboutSeoQuery, AboutSeoQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AboutSeoQuery, AboutSeoQueryVariables>(
    AboutSeoDocument,
    options,
  );
}
export type AboutSeoQueryHookResult = ReturnType<typeof useAboutSeoQuery>;
export type AboutSeoLazyQueryHookResult = ReturnType<
  typeof useAboutSeoLazyQuery
>;
export type AboutSeoSuspenseQueryHookResult = ReturnType<
  typeof useAboutSeoSuspenseQuery
>;
export type AboutSeoQueryResult = Apollo.QueryResult<
  AboutSeoQuery,
  AboutSeoQueryVariables
>;
export const FooterDocument = gql`
  query Footer($locale: I18NLocaleCode) {
    footer(locale: $locale) {
      data {
        attributes {
          column {
            lable
            link {
              label
              path
              openInNewTab
              file {
                ...uploadFile
              }
            }
          }
          policies {
            label
            link {
              label
              path
              openInNewTab
              file {
                ...uploadFile
              }
            }
          }
          socials {
            label
            instagram
            linkenin
            tiktok
            youtube
          }
          contact {
            title
            address {
              ...navLink
            }
            email {
              ...navLink
            }
          }
        }
      }
    }
  }
  ${UploadFileFragmentDoc}
  ${NavLinkFragmentDoc}
`;

/**
 * __useFooterQuery__
 *
 * To run a query within a React component, call `useFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFooterQuery(
  baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export function useFooterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FooterQuery, FooterQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FooterQuery, FooterQueryVariables>(
    FooterDocument,
    options,
  );
}
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterSuspenseQueryHookResult = ReturnType<
  typeof useFooterSuspenseQuery
>;
export type FooterQueryResult = Apollo.QueryResult<
  FooterQuery,
  FooterQueryVariables
>;
export const FormDocument = gql`
  query Form($locale: I18NLocaleCode) {
    getInTouch(locale: $locale) {
      data {
        attributes {
          anchor
          firstLine
          secondLine
          title
          options {
            value
            label
            isFullWidth
            type
          }
          checkBox {
            labelBefore
            labelLink
            areaLabel
            path
          }
          button
          modal {
            title
            description
          }
        }
      }
    }
  }
`;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFormQuery(
  baseOptions?: Apollo.QueryHookOptions<FormQuery, FormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
}
export function useFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(
    FormDocument,
    options,
  );
}
export function useFormSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FormQuery, FormQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FormQuery, FormQueryVariables>(
    FormDocument,
    options,
  );
}
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormSuspenseQueryHookResult = ReturnType<
  typeof useFormSuspenseQuery
>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;
export const NavigationDocument = gql`
  query Navigation($locale: I18NLocaleCode) {
    navigation(locale: $locale) {
      data {
        id
        attributes {
          closedMenuLinks {
            ...navLink
          }
          openMenuColumns {
            ...openMenuColumns
          }
          companyContactInfo {
            name
            address
          }
          homeLink {
            path
            areaLabel
          }
        }
      }
    }
  }
  ${NavLinkFragmentDoc}
  ${OpenMenuColumnsFragmentDoc}
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavigationQuery,
    NavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export function useNavigationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        NavigationQuery,
        NavigationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<
  typeof useNavigationLazyQuery
>;
export type NavigationSuspenseQueryHookResult = ReturnType<
  typeof useNavigationSuspenseQuery
>;
export type NavigationQueryResult = Apollo.QueryResult<
  NavigationQuery,
  NavigationQueryVariables
>;
export const ImpressumDocument = gql`
  query Impressum($locale: I18NLocaleCode) {
    impressum(locale: $locale) {
      data {
        attributes {
          title
          text
          lastUpdatedText
        }
      }
    }
  }
`;

/**
 * __useImpressumQuery__
 *
 * To run a query within a React component, call `useImpressumQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpressumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpressumQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useImpressumQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImpressumQuery,
    ImpressumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export function useImpressumLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpressumQuery,
    ImpressumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export function useImpressumSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ImpressumQuery, ImpressumQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ImpressumQuery, ImpressumQueryVariables>(
    ImpressumDocument,
    options,
  );
}
export type ImpressumQueryHookResult = ReturnType<typeof useImpressumQuery>;
export type ImpressumLazyQueryHookResult = ReturnType<
  typeof useImpressumLazyQuery
>;
export type ImpressumSuspenseQueryHookResult = ReturnType<
  typeof useImpressumSuspenseQuery
>;
export type ImpressumQueryResult = Apollo.QueryResult<
  ImpressumQuery,
  ImpressumQueryVariables
>;
export const SendContactFormDocument = gql`
  mutation SendContactForm($data: ContactFormDataInput!) {
    sendContactForm(data: $data) {
      success
    }
  }
`;
export type SendContactFormMutationFn = Apollo.MutationFunction<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;

/**
 * __useSendContactFormMutation__
 *
 * To run a mutation, you first call `useSendContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactFormMutation, { data, loading, error }] = useSendContactFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendContactFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendContactFormMutation,
    SendContactFormMutationVariables
  >(SendContactFormDocument, options);
}
export type SendContactFormMutationHookResult = ReturnType<
  typeof useSendContactFormMutation
>;
export type SendContactFormMutationResult =
  Apollo.MutationResult<SendContactFormMutation>;
export type SendContactFormMutationOptions = Apollo.BaseMutationOptions<
  SendContactFormMutation,
  SendContactFormMutationVariables
>;
export const PrivacyPolicyDocument = gql`
  query PrivacyPolicy($locale: I18NLocaleCode) {
    privacyPolicy(locale: $locale) {
      data {
        attributes {
          policies {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function usePrivacyPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
    PrivacyPolicyDocument,
    options,
  );
}
export function usePrivacyPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PrivacyPolicyQuery,
        PrivacyPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
  >(PrivacyPolicyDocument, options);
}
export type PrivacyPolicyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyQuery
>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyLazyQuery
>;
export type PrivacyPolicySuspenseQueryHookResult = ReturnType<
  typeof usePrivacyPolicySuspenseQuery
>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<
  PrivacyPolicyQuery,
  PrivacyPolicyQueryVariables
>;
export const LegalNoticeDocument = gql`
  query LegalNotice($locale: I18NLocaleCode) {
    legalNotice(locale: $locale) {
      data {
        attributes {
          legalNotice {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useLegalNoticeQuery__
 *
 * To run a query within a React component, call `useLegalNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalNoticeQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useLegalNoticeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LegalNoticeQuery,
    LegalNoticeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export function useLegalNoticeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegalNoticeQuery,
    LegalNoticeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export function useLegalNoticeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LegalNoticeQuery,
        LegalNoticeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LegalNoticeQuery, LegalNoticeQueryVariables>(
    LegalNoticeDocument,
    options,
  );
}
export type LegalNoticeQueryHookResult = ReturnType<typeof useLegalNoticeQuery>;
export type LegalNoticeLazyQueryHookResult = ReturnType<
  typeof useLegalNoticeLazyQuery
>;
export type LegalNoticeSuspenseQueryHookResult = ReturnType<
  typeof useLegalNoticeSuspenseQuery
>;
export type LegalNoticeQueryResult = Apollo.QueryResult<
  LegalNoticeQuery,
  LegalNoticeQueryVariables
>;
export const CookiePolicyDocument = gql`
  query CookiePolicy($locale: I18NLocaleCode) {
    cookiePolicy(locale: $locale) {
      data {
        attributes {
          cookiePolicy {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useCookiePolicyQuery__
 *
 * To run a query within a React component, call `useCookiePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiePolicyQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCookiePolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiePolicyQuery,
    CookiePolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export function useCookiePolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiePolicyQuery,
        CookiePolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiePolicyQuery, CookiePolicyQueryVariables>(
    CookiePolicyDocument,
    options,
  );
}
export type CookiePolicyQueryHookResult = ReturnType<
  typeof useCookiePolicyQuery
>;
export type CookiePolicyLazyQueryHookResult = ReturnType<
  typeof useCookiePolicyLazyQuery
>;
export type CookiePolicySuspenseQueryHookResult = ReturnType<
  typeof useCookiePolicySuspenseQuery
>;
export type CookiePolicyQueryResult = Apollo.QueryResult<
  CookiePolicyQuery,
  CookiePolicyQueryVariables
>;
export const WhistleblowingPolicyDocument = gql`
  query WhistleblowingPolicy($locale: I18NLocaleCode) {
    whistleblowingPolicy(locale: $locale) {
      data {
        attributes {
          policies {
            ...policyFields
          }
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${PolicyFieldsFragmentDoc}
  ${SeoFragmentDoc}
`;

/**
 * __useWhistleblowingPolicyQuery__
 *
 * To run a query within a React component, call `useWhistleblowingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhistleblowingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhistleblowingPolicyQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useWhistleblowingPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export function useWhistleblowingPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WhistleblowingPolicyQuery,
        WhistleblowingPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WhistleblowingPolicyQuery,
    WhistleblowingPolicyQueryVariables
  >(WhistleblowingPolicyDocument, options);
}
export type WhistleblowingPolicyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyQuery
>;
export type WhistleblowingPolicyLazyQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicyLazyQuery
>;
export type WhistleblowingPolicySuspenseQueryHookResult = ReturnType<
  typeof useWhistleblowingPolicySuspenseQuery
>;
export type WhistleblowingPolicyQueryResult = Apollo.QueryResult<
  WhistleblowingPolicyQuery,
  WhistleblowingPolicyQueryVariables
>;
export const CookiesSettingsDocument = gql`
  query CookiesSettings($locale: I18NLocaleCode) {
    cookiesSetting(locale: $locale) {
      data {
        id
        attributes {
          actionButtonLabel
          necessaryCookiesHeading
          necessaryCookiesTable {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          optionalCookiesHeading
          optionalCookiesTable {
            richText
            tableHead {
              headingItem
            }
            tableRow {
              table_rows {
                data {
                  attributes {
                    rows {
                      item
                    }
                  }
                }
              }
            }
          }
          title
          redirectButton {
            label
            path
          }
        }
      }
    }
  }
`;

/**
 * __useCookiesSettingsQuery__
 *
 * To run a query within a React component, call `useCookiesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesSettingsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCookiesSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesSettingsQuery, CookiesSettingsQueryVariables>(
    CookiesSettingsDocument,
    options,
  );
}
export function useCookiesSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export function useCookiesSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesSettingsQuery,
        CookiesSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CookiesSettingsQuery,
    CookiesSettingsQueryVariables
  >(CookiesSettingsDocument, options);
}
export type CookiesSettingsQueryHookResult = ReturnType<
  typeof useCookiesSettingsQuery
>;
export type CookiesSettingsLazyQueryHookResult = ReturnType<
  typeof useCookiesSettingsLazyQuery
>;
export type CookiesSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCookiesSettingsSuspenseQuery
>;
export type CookiesSettingsQueryResult = Apollo.QueryResult<
  CookiesSettingsQuery,
  CookiesSettingsQueryVariables
>;
export const CookiesBarDocument = gql`
  query CookiesBar($locale: I18NLocaleCode) {
    cookieBanner(locale: $locale) {
      data {
        id
        attributes {
          heading
          editButtonLabel
          declineButtonLabel
          acceptButtonLabel
          text
          extraLinks {
            ...button
          }
          toggleText {
            firstText
            secondText
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
`;

/**
 * __useCookiesBarQuery__
 *
 * To run a query within a React component, call `useCookiesBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCookiesBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCookiesBarQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCookiesBarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CookiesBarQuery,
    CookiesBarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export function useCookiesBarSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CookiesBarQuery,
        CookiesBarQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CookiesBarQuery, CookiesBarQueryVariables>(
    CookiesBarDocument,
    options,
  );
}
export type CookiesBarQueryHookResult = ReturnType<typeof useCookiesBarQuery>;
export type CookiesBarLazyQueryHookResult = ReturnType<
  typeof useCookiesBarLazyQuery
>;
export type CookiesBarSuspenseQueryHookResult = ReturnType<
  typeof useCookiesBarSuspenseQuery
>;
export type CookiesBarQueryResult = Apollo.QueryResult<
  CookiesBarQuery,
  CookiesBarQueryVariables
>;
export const SineStackHeroDocument = gql`
  query SineStackHero($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        id
        attributes {
          heroImageSection {
            ...heroImageSection
          }
        }
      }
    }
  }
  ${HeroImageSectionFragmentDoc}
`;

/**
 * __useSineStackHeroQuery__
 *
 * To run a query within a React component, call `useSineStackHeroQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackHeroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackHeroQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackHeroQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackHeroQuery,
    SineStackHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SineStackHeroQuery, SineStackHeroQueryVariables>(
    SineStackHeroDocument,
    options,
  );
}
export function useSineStackHeroLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackHeroQuery,
    SineStackHeroQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SineStackHeroQuery, SineStackHeroQueryVariables>(
    SineStackHeroDocument,
    options,
  );
}
export function useSineStackHeroSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackHeroQuery,
        SineStackHeroQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackHeroQuery,
    SineStackHeroQueryVariables
  >(SineStackHeroDocument, options);
}
export type SineStackHeroQueryHookResult = ReturnType<
  typeof useSineStackHeroQuery
>;
export type SineStackHeroLazyQueryHookResult = ReturnType<
  typeof useSineStackHeroLazyQuery
>;
export type SineStackHeroSuspenseQueryHookResult = ReturnType<
  typeof useSineStackHeroSuspenseQuery
>;
export type SineStackHeroQueryResult = Apollo.QueryResult<
  SineStackHeroQuery,
  SineStackHeroQueryVariables
>;
export const SineStackCardsSectionDocument = gql`
  query SineStackCardsSection($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          cardsSection {
            ...cardsSection
          }
        }
      }
    }
  }
  ${CardsSectionFragmentDoc}
`;

/**
 * __useSineStackCardsSectionQuery__
 *
 * To run a query within a React component, call `useSineStackCardsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackCardsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackCardsSectionQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackCardsSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackCardsSectionQuery,
    SineStackCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SineStackCardsSectionQuery,
    SineStackCardsSectionQueryVariables
  >(SineStackCardsSectionDocument, options);
}
export function useSineStackCardsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackCardsSectionQuery,
    SineStackCardsSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SineStackCardsSectionQuery,
    SineStackCardsSectionQueryVariables
  >(SineStackCardsSectionDocument, options);
}
export function useSineStackCardsSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackCardsSectionQuery,
        SineStackCardsSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackCardsSectionQuery,
    SineStackCardsSectionQueryVariables
  >(SineStackCardsSectionDocument, options);
}
export type SineStackCardsSectionQueryHookResult = ReturnType<
  typeof useSineStackCardsSectionQuery
>;
export type SineStackCardsSectionLazyQueryHookResult = ReturnType<
  typeof useSineStackCardsSectionLazyQuery
>;
export type SineStackCardsSectionSuspenseQueryHookResult = ReturnType<
  typeof useSineStackCardsSectionSuspenseQuery
>;
export type SineStackCardsSectionQueryResult = Apollo.QueryResult<
  SineStackCardsSectionQuery,
  SineStackCardsSectionQueryVariables
>;
export const SineStackNumbersDocument = gql`
  query SineStackNumbers($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          numbersSection {
            ...numbersSection
          }
        }
      }
    }
  }
  ${NumbersSectionFragmentDoc}
`;

/**
 * __useSineStackNumbersQuery__
 *
 * To run a query within a React component, call `useSineStackNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackNumbersQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackNumbersQuery,
    SineStackNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SineStackNumbersQuery, SineStackNumbersQueryVariables>(
    SineStackNumbersDocument,
    options,
  );
}
export function useSineStackNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackNumbersQuery,
    SineStackNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SineStackNumbersQuery,
    SineStackNumbersQueryVariables
  >(SineStackNumbersDocument, options);
}
export function useSineStackNumbersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackNumbersQuery,
        SineStackNumbersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackNumbersQuery,
    SineStackNumbersQueryVariables
  >(SineStackNumbersDocument, options);
}
export type SineStackNumbersQueryHookResult = ReturnType<
  typeof useSineStackNumbersQuery
>;
export type SineStackNumbersLazyQueryHookResult = ReturnType<
  typeof useSineStackNumbersLazyQuery
>;
export type SineStackNumbersSuspenseQueryHookResult = ReturnType<
  typeof useSineStackNumbersSuspenseQuery
>;
export type SineStackNumbersQueryResult = Apollo.QueryResult<
  SineStackNumbersQuery,
  SineStackNumbersQueryVariables
>;
export const SineStackVideoFeatureDocument = gql`
  query SineStackVideoFeature($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          videoFeatureSection {
            ...videoFeatureSection
          }
        }
      }
    }
  }
  ${VideoFeatureSectionFragmentDoc}
`;

/**
 * __useSineStackVideoFeatureQuery__
 *
 * To run a query within a React component, call `useSineStackVideoFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackVideoFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackVideoFeatureQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackVideoFeatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackVideoFeatureQuery,
    SineStackVideoFeatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SineStackVideoFeatureQuery,
    SineStackVideoFeatureQueryVariables
  >(SineStackVideoFeatureDocument, options);
}
export function useSineStackVideoFeatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackVideoFeatureQuery,
    SineStackVideoFeatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SineStackVideoFeatureQuery,
    SineStackVideoFeatureQueryVariables
  >(SineStackVideoFeatureDocument, options);
}
export function useSineStackVideoFeatureSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackVideoFeatureQuery,
        SineStackVideoFeatureQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackVideoFeatureQuery,
    SineStackVideoFeatureQueryVariables
  >(SineStackVideoFeatureDocument, options);
}
export type SineStackVideoFeatureQueryHookResult = ReturnType<
  typeof useSineStackVideoFeatureQuery
>;
export type SineStackVideoFeatureLazyQueryHookResult = ReturnType<
  typeof useSineStackVideoFeatureLazyQuery
>;
export type SineStackVideoFeatureSuspenseQueryHookResult = ReturnType<
  typeof useSineStackVideoFeatureSuspenseQuery
>;
export type SineStackVideoFeatureQueryResult = Apollo.QueryResult<
  SineStackVideoFeatureQuery,
  SineStackVideoFeatureQueryVariables
>;
export const SineStackImageFeatureDocument = gql`
  query SineStackImageFeature($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          imageFeatureSection {
            ...imageFeatureSection
          }
        }
      }
    }
  }
  ${ImageFeatureSectionFragmentDoc}
`;

/**
 * __useSineStackImageFeatureQuery__
 *
 * To run a query within a React component, call `useSineStackImageFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackImageFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackImageFeatureQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackImageFeatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackImageFeatureQuery,
    SineStackImageFeatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SineStackImageFeatureQuery,
    SineStackImageFeatureQueryVariables
  >(SineStackImageFeatureDocument, options);
}
export function useSineStackImageFeatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackImageFeatureQuery,
    SineStackImageFeatureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SineStackImageFeatureQuery,
    SineStackImageFeatureQueryVariables
  >(SineStackImageFeatureDocument, options);
}
export function useSineStackImageFeatureSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackImageFeatureQuery,
        SineStackImageFeatureQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackImageFeatureQuery,
    SineStackImageFeatureQueryVariables
  >(SineStackImageFeatureDocument, options);
}
export type SineStackImageFeatureQueryHookResult = ReturnType<
  typeof useSineStackImageFeatureQuery
>;
export type SineStackImageFeatureLazyQueryHookResult = ReturnType<
  typeof useSineStackImageFeatureLazyQuery
>;
export type SineStackImageFeatureSuspenseQueryHookResult = ReturnType<
  typeof useSineStackImageFeatureSuspenseQuery
>;
export type SineStackImageFeatureQueryResult = Apollo.QueryResult<
  SineStackImageFeatureQuery,
  SineStackImageFeatureQueryVariables
>;
export const SineStackTextImageSectionDocument = gql`
  query SineStackTextImageSection($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          textImageSection {
            ...textImageSection
          }
        }
      }
    }
  }
  ${TextImageSectionFragmentDoc}
`;

/**
 * __useSineStackTextImageSectionQuery__
 *
 * To run a query within a React component, call `useSineStackTextImageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackTextImageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackTextImageSectionQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackTextImageSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackTextImageSectionQuery,
    SineStackTextImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SineStackTextImageSectionQuery,
    SineStackTextImageSectionQueryVariables
  >(SineStackTextImageSectionDocument, options);
}
export function useSineStackTextImageSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackTextImageSectionQuery,
    SineStackTextImageSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SineStackTextImageSectionQuery,
    SineStackTextImageSectionQueryVariables
  >(SineStackTextImageSectionDocument, options);
}
export function useSineStackTextImageSectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackTextImageSectionQuery,
        SineStackTextImageSectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackTextImageSectionQuery,
    SineStackTextImageSectionQueryVariables
  >(SineStackTextImageSectionDocument, options);
}
export type SineStackTextImageSectionQueryHookResult = ReturnType<
  typeof useSineStackTextImageSectionQuery
>;
export type SineStackTextImageSectionLazyQueryHookResult = ReturnType<
  typeof useSineStackTextImageSectionLazyQuery
>;
export type SineStackTextImageSectionSuspenseQueryHookResult = ReturnType<
  typeof useSineStackTextImageSectionSuspenseQuery
>;
export type SineStackTextImageSectionQueryResult = Apollo.QueryResult<
  SineStackTextImageSectionQuery,
  SineStackTextImageSectionQueryVariables
>;
export const SineStackTwoVideosGridDocument = gql`
  query SineStackTwoVideosGrid($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          twoVideosGrid {
            ...twoVideosGrid
          }
        }
      }
    }
  }
  ${TwoVideosGridFragmentDoc}
`;

/**
 * __useSineStackTwoVideosGridQuery__
 *
 * To run a query within a React component, call `useSineStackTwoVideosGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackTwoVideosGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackTwoVideosGridQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackTwoVideosGridQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackTwoVideosGridQuery,
    SineStackTwoVideosGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SineStackTwoVideosGridQuery,
    SineStackTwoVideosGridQueryVariables
  >(SineStackTwoVideosGridDocument, options);
}
export function useSineStackTwoVideosGridLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackTwoVideosGridQuery,
    SineStackTwoVideosGridQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SineStackTwoVideosGridQuery,
    SineStackTwoVideosGridQueryVariables
  >(SineStackTwoVideosGridDocument, options);
}
export function useSineStackTwoVideosGridSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackTwoVideosGridQuery,
        SineStackTwoVideosGridQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackTwoVideosGridQuery,
    SineStackTwoVideosGridQueryVariables
  >(SineStackTwoVideosGridDocument, options);
}
export type SineStackTwoVideosGridQueryHookResult = ReturnType<
  typeof useSineStackTwoVideosGridQuery
>;
export type SineStackTwoVideosGridLazyQueryHookResult = ReturnType<
  typeof useSineStackTwoVideosGridLazyQuery
>;
export type SineStackTwoVideosGridSuspenseQueryHookResult = ReturnType<
  typeof useSineStackTwoVideosGridSuspenseQuery
>;
export type SineStackTwoVideosGridQueryResult = Apollo.QueryResult<
  SineStackTwoVideosGridQuery,
  SineStackTwoVideosGridQueryVariables
>;
export const SineStackLargeImageDocument = gql`
  query SineStackLargeImage($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          largeImageSection {
            ...largeImageSection
          }
        }
      }
    }
  }
  ${LargeImageSectionFragmentDoc}
`;

/**
 * __useSineStackLargeImageQuery__
 *
 * To run a query within a React component, call `useSineStackLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackLargeImageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackLargeImageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackLargeImageQuery,
    SineStackLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SineStackLargeImageQuery,
    SineStackLargeImageQueryVariables
  >(SineStackLargeImageDocument, options);
}
export function useSineStackLargeImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackLargeImageQuery,
    SineStackLargeImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SineStackLargeImageQuery,
    SineStackLargeImageQueryVariables
  >(SineStackLargeImageDocument, options);
}
export function useSineStackLargeImageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackLargeImageQuery,
        SineStackLargeImageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SineStackLargeImageQuery,
    SineStackLargeImageQueryVariables
  >(SineStackLargeImageDocument, options);
}
export type SineStackLargeImageQueryHookResult = ReturnType<
  typeof useSineStackLargeImageQuery
>;
export type SineStackLargeImageLazyQueryHookResult = ReturnType<
  typeof useSineStackLargeImageLazyQuery
>;
export type SineStackLargeImageSuspenseQueryHookResult = ReturnType<
  typeof useSineStackLargeImageSuspenseQuery
>;
export type SineStackLargeImageQueryResult = Apollo.QueryResult<
  SineStackLargeImageQuery,
  SineStackLargeImageQueryVariables
>;
export const SineStackSeoDocument = gql`
  query SineStackSeo($locale: I18NLocaleCode) {
    sineStack(locale: $locale) {
      data {
        attributes {
          seo {
            ...seo
          }
        }
      }
    }
  }
  ${SeoFragmentDoc}
`;

/**
 * __useSineStackSeoQuery__
 *
 * To run a query within a React component, call `useSineStackSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSineStackSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSineStackSeoQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSineStackSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SineStackSeoQuery,
    SineStackSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SineStackSeoQuery, SineStackSeoQueryVariables>(
    SineStackSeoDocument,
    options,
  );
}
export function useSineStackSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SineStackSeoQuery,
    SineStackSeoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SineStackSeoQuery, SineStackSeoQueryVariables>(
    SineStackSeoDocument,
    options,
  );
}
export function useSineStackSeoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SineStackSeoQuery,
        SineStackSeoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SineStackSeoQuery, SineStackSeoQueryVariables>(
    SineStackSeoDocument,
    options,
  );
}
export type SineStackSeoQueryHookResult = ReturnType<
  typeof useSineStackSeoQuery
>;
export type SineStackSeoLazyQueryHookResult = ReturnType<
  typeof useSineStackSeoLazyQuery
>;
export type SineStackSeoSuspenseQueryHookResult = ReturnType<
  typeof useSineStackSeoSuspenseQuery
>;
export type SineStackSeoQueryResult = Apollo.QueryResult<
  SineStackSeoQuery,
  SineStackSeoQueryVariables
>;
