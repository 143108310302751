import {
  Header,
  HeaderProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';
import { useRouter } from 'next/router';

import { NavigationQuery } from '@/api';

interface NavigationProps {
  invertedColor?: boolean;
  data: NavigationQuery;
}

export const Navigation = ({ invertedColor, data }: NavigationProps) => {
  const navData = data?.navigation?.data?.attributes;
  const { pathname } = useRouter();
  if (!navData) return null;

  const headerProps: HeaderProps = {
    useCase: 'energy' as useCaseType,
    menuColsOpened: (navData?.openMenuColumns || []).map((column) => ({
      label: column?.label || '',
      menuLinks: (column?.link || []).map((link) => ({
        label: link?.label || '',
        path: link?.path || '',
        openInNewTab: !!link?.openInNewTab,
      })),
      socials: {
        instagram: column?.socials?.instagram || '',
        linkedin: column?.socials?.linkedIn || '',
        youtube: column?.socials?.youtube || '',
        tiktok: column?.socials?.tiktok || '',
      },
    })),
    menuLinksClosed: (navData.closedMenuLinks || []).map((link) => ({
      label: link?.label || '',
      path: link?.path || '',
      openInNewTab: !!link?.openInNewTab,
    })),
    activePath: pathname,
    companyContactInfo: {
      name: navData.companyContactInfo?.name || '',
      address: navData.companyContactInfo?.address || '',
    },
    homeLink: {
      path: navData.homeLink?.path || '',
      areaLabel: navData.homeLink?.areaLabel || '',
    },
  };

  return <Header {...headerProps} invertedColor={invertedColor} />;
};
