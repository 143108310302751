import {
  GetInTouchSection,
  GetInTouchSectionProps,
  inputTextType,
  inputType,
  localeType,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import {
  ContactFormDataInput,
  FormQuery,
  useSendContactFormMutation,
} from '@/api';

export const Form = ({ data }: { data: FormQuery }) => {
  const router = useRouter();
  const { locale } = router;

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendForm] = useSendContactFormMutation({
    onCompleted() {
      setSuccess(true);
    },
  });

  const onSubmit: SubmitHandler<ContactFormDataInput> = async (data) => {
    setLoading(true);
    await sendForm({ variables: { data } });
  };

  const formData = data?.getInTouch?.data?.attributes;

  const formProps: GetInTouchSectionProps = {
    title: formData?.title || '',
    descriptionFirstLine: formData?.firstLine || '',
    descriptionSecondLine: formData?.secondLine || '',
    onSubmit,
    loading,
    success,
    setLoading: () => setLoading(!loading),
    setSuccess: () => setSuccess(!success),
    useCase: 'energy' as useCaseType,
    inputs: (formData?.options || []).map((input) => ({
      value: input?.value as inputTextType,
      label: input?.label || '',
      isFullWidth: !!input?.isFullWidth,
      type: input?.type as inputType,
    })),
    buttonLabel: formData?.button || '',
    checkbox: {
      labelBefore: formData?.checkBox?.labelBefore || '',
      labelLink: formData?.checkBox?.labelLink || '',
      areaLabel: formData?.checkBox?.areaLabel || '',
      path: formData?.checkBox?.path || '',
    },
    locale: locale as localeType,
    modal: {
      title: formData?.modal?.title || '',
      description: formData?.modal?.description || '',
    },
  };

  return <GetInTouchSection {...formProps} />;
};
